// actionTypes.js

export const SAVE_MORTGAGE_CALCULATOR_SUCCESS =
  "SAVE_MORTGAGE_CALCULATOR_SUCCESS";
export const SAVE_MORTGAGE_CALCULATOR_FAILURE =
  "SAVE_MORTGAGE_CALCULATOR_FAILURE";

// Campaign fetching actions
export const FETCH_CAMPAIGNS_REQUEST = "FETCH_CAMPAIGNS_REQUEST";
export const FETCH_CAMPAIGNS_SUCCESS = "FETCH_CAMPAIGNS_SUCCESS";
export const FETCH_CAMPAIGNS_FAILURE = "FETCH_CAMPAIGNS_FAILURE";

// Actions for creating a new campaign
export const CREATE_CAMPAIGN_REQUEST = "CREATE_CAMPAIGN_REQUEST";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAILURE = "CREATE_CAMPAIGN_FAILURE";

// Actions for associating listings with a campaign
export const ASSOCIATE_LISTINGS_REQUEST = "ASSOCIATE_LISTINGS_REQUEST";
export const ASSOCIATE_LISTINGS_SUCCESS = "ASSOCIATE_LISTINGS_SUCCESS";
export const ASSOCIATE_LISTINGS_FAILURE = "ASSOCIATE_LISTINGS_FAILURE";

// Actions for updating a campaign
export const UPDATE_CAMPAIGN_REQUEST = "UPDATE_CAMPAIGN_REQUEST";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAILURE = "UPDATE_CAMPAIGN_FAILURE";

// Actions for deleting a campaign
export const DELETE_CAMPAIGN_REQUEST = "DELETE_CAMPAIGN_REQUEST";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_FAILURE = "DELETE_CAMPAIGN_FAILURE";

// Actions for fetching a single campaign
export const FETCH_CAMPAIGN_REQUEST = "FETCH_CAMPAIGN_REQUEST";
export const FETCH_CAMPAIGN_SUCCESS = "FETCH_CAMPAIGN_SUCCESS";
export const FETCH_CAMPAIGN_FAILURE = "FETCH_CAMPAIGN_FAILURE";

// Actions for removing a listing from a campaign
export const REMOVE_LISTING_FROM_CAMPAIGN_REQUEST =
  "REMOVE_LISTING_FROM_CAMPAIGN_REQUEST";
export const REMOVE_LISTING_FROM_CAMPAIGN_SUCCESS =
  "REMOVE_LISTING_FROM_CAMPAIGN_SUCCESS";
export const REMOVE_LISTING_FROM_CAMPAIGN_FAILURE =
  "REMOVE_LISTING_FROM_CAMPAIGN_FAILURE";

// Actions for removing all listings from a campaign
export const REMOVE_ALL_LISTINGS_FROM_CAMPAIGN_REQUEST =
  "REMOVE_ALL_LISTINGS_FROM_CAMPAIGN_REQUEST";
export const REMOVE_ALL_LISTINGS_FROM_CAMPAIGN_SUCCESS =
  "REMOVE_ALL_LISTINGS_FROM_CAMPAIGN_SUCCESS";
export const REMOVE_ALL_LISTINGS_FROM_CAMPAIGN_FAILURE =
  "REMOVE_ALL_LISTINGS_FROM_CAMPAIGN_FAILURE";

// Actions for uploading a listing image
export const UPLOAD_LISTING_IMAGE_REQUEST = "UPLOAD_LISTING_IMAGE_REQUEST";
export const UPLOAD_LISTING_IMAGE_SUCCESS = "UPLOAD_LISTING_IMAGE_SUCCESS";
export const UPLOAD_LISTING_IMAGE_FAILURE = "UPLOAD_LISTING_IMAGE_FAILURE";

export const FETCH_CAMPAIGN_BY_SID_REQUEST = "FETCH_CAMPAIGN_BY_SID_REQUEST";
export const FETCH_CAMPAIGN_BY_SID_SUCCESS = "FETCH_CAMPAIGN_BY_SID_SUCCESS";
export const FETCH_CAMPAIGN_BY_SID_FAILURE = "FETCH_CAMPAIGN_BY_SID_FAILURE";

export const REMOVE_LISTINGS_FROM_CAMPAIGN_REQUEST =
  "REMOVE_LISTINGS_FROM_CAMPAIGN_REQUEST";
export const REMOVE_LISTINGS_FROM_CAMPAIGN_SUCCESS =
  "REMOVE_LISTINGS_FROM_CAMPAIGN_SUCCESS";
export const REMOVE_LISTINGS_FROM_CAMPAIGN_FAILURE =
  "REMOVE_LISTINGS_FROM_CAMPAIGN_FAILURE";
