import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import CalculateIcon from "@mui/icons-material/Calculate";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { saveMortgageCalculatorData } from "../actions/campaignActions";

const MortgageCalculator = ({ SID, campaignId }) => {
  const mortgageCalculatorData = useSelector(
    (state) => state.mortgageCalculatorData
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [principal, setPrincipal] = useState("");
  const [interestRate, setInterestRate] = useState(5);
  const [years, setYears] = useState(25);
  const [downPaymentPercentage, setDownPaymentPercentage] = useState(5);
  const [monthlyPayment, setMonthlyPayment] = useState("");

  const calculateMonthlyPayment = () => {
    const principalAmount = parseFloat(principal);
    const downPaymentAmount = (downPaymentPercentage / 100) * principalAmount;
    const loanAmount = principalAmount - downPaymentAmount;
    const annualInterestRate = parseFloat(interestRate) / 100;
    const monthlyInterestRate = annualInterestRate / 12;
    const numberOfPayments = parseInt(years) * 12;

    if (
      !isNaN(loanAmount) &&
      !isNaN(monthlyInterestRate) &&
      !isNaN(numberOfPayments)
    ) {
      const payment = (
        (loanAmount * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments))
      ).toFixed(2);
      setMonthlyPayment(payment);
    }
  };

  useEffect(() => {
    if (principal) {
      calculateMonthlyPayment();
    }
  }, [principal, calculateMonthlyPayment]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const calculatePrincipalFromMonthlyPayment = () => {
    const monthlyInterestRate = parseFloat(interestRate) / 100 / 12;
    const numberOfPayments = parseInt(years) * 12;
    const payment = parseFloat(monthlyPayment);

    if (
      !isNaN(monthlyInterestRate) &&
      !isNaN(numberOfPayments) &&
      !isNaN(payment)
    ) {
      const loanAmount =
        (payment * (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments))) /
        monthlyInterestRate;
      const principalAmount = (
        loanAmount /
        (1 - downPaymentPercentage / 100)
      ).toFixed(2);
      setPrincipal(principalAmount);
    }
  };

  const handleDownPaymentChange = (event, newValue) => {
    setDownPaymentPercentage(newValue);
  };

  const handlePrincipalChange = (event) => {
    const value = event.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
    setPrincipal(value);
    setMonthlyPayment(""); // Blank out monthly payment when principal is changed
  };

  const handleMonthlyPaymentChange = (event) => {
    const value = event.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
    setMonthlyPayment(value);
    setPrincipal(""); // Blank out principal amount when monthly payment is changed
  };

  const handleInterestRateChange = (event) => {
    setInterestRate(event.target.value);
  };

  const handleYearsChange = (event) => {
    setYears(event.target.value);
  };

  const handleCalculate = () => {
    if (principal && interestRate && years) {
      calculateMonthlyPayment();
      dispatch(
        saveMortgageCalculatorData(campaignId, {
          principal,
          interestRate,
          years,
          downPaymentPercentage,
          monthlyPayment,
        })
      );
    }
  };

  const handleCalculateFromMonthlyPayment = () => {
    if (monthlyPayment && interestRate && years) {
      calculatePrincipalFromMonthlyPayment();
      dispatch(
        saveMortgageCalculatorData(campaignId, {
          principal,
          interestRate,
          years,
          downPaymentPercentage,
          monthlyPayment,
        })
      );
    }
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        sx={{
          border: "2px solid hsl(210deg 76% 47%)",
          borderRadius: "8px",
          p: 2,
          mt: 1,
          bgcolor: "white",
          textAlign: "center",
          cursor: "pointer", // Add cursor style for mouse icon
        }}
      >
        <Typography variant="h5" gutterBottom>
          Mortgage Calculator
        </Typography>
        <Typography variant="body1">
          Use this calculator to determine your monthly payments. You can adjust
          any field, and the other fields will update accordingly.
        </Typography>
        <IconButton
          onClick={handleOpen}
          color="primary"
          aria-label="mortgage calculator"
        >
          <CalculateIcon fontSize="large" />
        </IconButton>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              width: "100%",
              bgcolor: "hsl(210deg 76% 47%)",
              pl: 2,
              pr: 2,
              pt: 1,
              pb: 1,
              mb: 1,
              display: "flex",
              justifyContent: "space-between", // Center align horizontally
              alignItems: "stretch", // Align items vertically if needed
            }}
          >
            <CalculateIcon fontSize="large" sx={{ color: "white" }} />
            <Typography
              variant="h6"
              component="h2"
              fontWeight={"bold"}
              color={"white"}
              sx={{ ml: 1 }} // Adjust margin left for spacing
            >
              Mortgage Calculator
            </Typography>
            <IconButton
              onClick={() => handleClose()}
              sx={{ float: "right", color: "white" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            label="Principal Amount"
            margin="normal"
            value={Number(principal).toLocaleString()}
            onChange={handlePrincipalChange}
            InputProps={{
              startAdornment: <Typography variant="body1">$</Typography>,
            }}
          />
          <TextField
            fullWidth
            label="Annual Interest Rate (%)"
            margin="normal"
            value={interestRate}
            onChange={handleInterestRateChange}
          />
          <TextField
            fullWidth
            label="Loan Term (Years)"
            margin="normal"
            value={years}
            onChange={handleYearsChange}
          />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Down Payment: {downPaymentPercentage}%
          </Typography>
          <Slider
            value={downPaymentPercentage}
            onChange={handleDownPaymentChange}
            aria-labelledby="down-payment-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={5}
            max={100}
          />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Down Payment Amount: $
            {((principal * downPaymentPercentage) / 100).toLocaleString()}
          </Typography>
          <TextField
            fullWidth
            label="Monthly Payment"
            margin="normal"
            value={Number(monthlyPayment).toLocaleString()}
            onChange={handleMonthlyPaymentChange}
            onBlur={handleCalculateFromMonthlyPayment}
            InputProps={{
              startAdornment: <Typography variant="body1">$</Typography>,
            }}
          />
          <Button variant="contained" color="primary" onClick={handleCalculate}>
            Calculate
          </Button>
          &nbsp;
          <Button onClick={handleClose} variant="contained" color="error">
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default MortgageCalculator;
