import {
  UPLOAD_LISTING_IMAGE_REQUEST,
  UPLOAD_LISTING_IMAGE_SUCCESS,
  UPLOAD_LISTING_IMAGE_FAILURE,
  UPDATE_LISTING_REQUEST,
  UPDATE_LISTING_SUCCESS,
  UPDATE_LISTING_FAILURE,
  FETCH_LISTING_REQUEST,
  FETCH_LISTING_SUCCESS,
  FETCH_LISTING_FAILURE,
  CONTEST_ENTRY_SUCCESS,
  CONTEST_ENTRY_FAIL,
} from "../actions/listingActions";

const initialState = {
  listings: [],
  listing: null,
  uploading: false,
  updating: false,
  loading: false,
  error: null,
};

const listingReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_LISTING_IMAGE_REQUEST:
    case FETCH_LISTING_REQUEST:
    case UPDATE_LISTING_REQUEST:
      return { ...state, uploading: true, loading: true, error: null };

    case UPLOAD_LISTING_IMAGE_SUCCESS: {
      const { listingId, image } = action.payload;
      return {
        ...state,
        uploading: false,
        listings: state.listings.map((listing) =>
          listing._id === listingId ? { ...listing, image } : listing
        ),
      };
    }
    case UPLOAD_LISTING_IMAGE_FAILURE:
    case FETCH_LISTING_FAILURE:
    case UPDATE_LISTING_FAILURE:
      return {
        ...state,
        uploading: false,
        loading: false,
        updating: false,
        error: action.payload,
      };

    case FETCH_LISTING_SUCCESS:
      return { ...state, loading: false, listing: action.payload };

    case UPDATE_LISTING_SUCCESS:
      const updatedListing = action.payload;
      return {
        ...state,
        updating: false,
        listing: updatedListing,
        listings: state.listings.map((listing) =>
          listing._id === updatedListing._id ? updatedListing : listing
        ),
      };
    case CONTEST_ENTRY_SUCCESS:
      return {
        ...state,
        listing: action.payload,
        error: null,
      };
    case CONTEST_ENTRY_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default listingReducer;
