import axios from "axios";
import {
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_FAILURE,
  FETCH_CAMPAIGNS_REQUEST,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,
  ASSOCIATE_LISTINGS_SUCCESS,
  ASSOCIATE_LISTINGS_FAILURE,
  FETCH_CAMPAIGN_SUCCESS,
  FETCH_CAMPAIGN_FAILURE,
  FETCH_CAMPAIGN_REQUEST,
  REMOVE_LISTING_FROM_CAMPAIGN_REQUEST,
  REMOVE_LISTING_FROM_CAMPAIGN_SUCCESS,
  REMOVE_LISTING_FROM_CAMPAIGN_FAILURE,
  REMOVE_LISTINGS_REQUEST,
  REMOVE_LISTINGS_SUCCESS,
  REMOVE_LISTINGS_FAILURE,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAILURE,
  SAVE_MORTGAGE_CALCULATOR_SUCCESS,
  SAVE_MORTGAGE_CALCULATOR_FAILURE,
  FETCH_CAMPAIGN_BY_SID_REQUEST,
  FETCH_CAMPAIGN_BY_SID_SUCCESS,
  FETCH_CAMPAIGN_BY_SID_FAILURE,
  // more imports
} from "./actionTypes";

// Action Creators

export const updateCampaign = (id, updatedData) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/campaigns/${id}`,
      updatedData
    );
    dispatch({ type: FETCH_CAMPAIGN_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_CAMPAIGN_FAILURE, payload: error.message });
  }
};

export const removeListingFromCampaign =
  (campaignId, listingId) => async (dispatch) => {
    dispatch({ type: REMOVE_LISTING_FROM_CAMPAIGN_REQUEST });

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/campaigns/${campaignId}/listings/${listingId}`
      );
      dispatch({
        type: REMOVE_LISTING_FROM_CAMPAIGN_SUCCESS,
        payload: response.data,
      });
      // Fetch the updated campaign data after successful removal
      dispatch(fetchCampaignById(campaignId));

    } catch (error) {
      dispatch({
        type: REMOVE_LISTING_FROM_CAMPAIGN_FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const removeAllListingsFromCampaign =
  (campaignId) => async (dispatch) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/campaigns/${campaignId}/listings`
      );
      dispatch(fetchCampaignById(campaignId));
    } catch (error) {
      dispatch({ type: FETCH_CAMPAIGN_FAILURE, payload: error.message });
    }
  };

export const removeListingsFromCampaign =
  (campaignId, listingIds) => async (dispatch) => {
    dispatch({ type: REMOVE_LISTING_FROM_CAMPAIGN_REQUEST });

    try {
      // Map through the listingIds array and delete each listing
      const deletePromises = listingIds.map((listingId) =>
        axios.delete(
          `${process.env.REACT_APP_API_URL}/campaigns/${campaignId}/listings/${listingId}`
        )
      );

      // Wait for all delete requests to complete
      await Promise.all(deletePromises);

      dispatch({
        type: REMOVE_LISTING_FROM_CAMPAIGN_SUCCESS,
        payload: { campaignId, listingIds },
      });

      // Fetch the updated campaign data after successful removal
      dispatch(fetchCampaignById(campaignId));
    } catch (error) {
      dispatch({
        type: REMOVE_LISTING_FROM_CAMPAIGN_FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const fetchCampaignsSuccess = (campaigns) => ({
  type: FETCH_CAMPAIGNS_SUCCESS,
  payload: campaigns,
});

export const fetchCampaignsFailure = (error) => ({
  type: FETCH_CAMPAIGNS_FAILURE,
  payload: error,
});

export const fetchCampaignsRequest = () => ({
  type: FETCH_CAMPAIGNS_REQUEST,
});

export const fetchCampaigns = () => async (dispatch) => {
  dispatch({ type: FETCH_CAMPAIGNS_REQUEST });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/campaigns`
    );
    // console.log("Campaigns fetched:", response.data);

    dispatch({ type: FETCH_CAMPAIGNS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_CAMPAIGNS_FAILURE, payload: error.message });
  }
};

export const fetchCampaignById = (campaignId) => async (dispatch) => {
  dispatch({ type: FETCH_CAMPAIGN_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/campaigns/${campaignId}`
    );
    dispatch({
      type: FETCH_CAMPAIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CAMPAIGN_FAILURE,
      payload: error.response
        ? error.response.data
        : { message: error.message },
    });
  }
};

export const fetchCampaignBySID = (SID) => async (dispatch) => {
  dispatch({ type: FETCH_CAMPAIGN_BY_SID_REQUEST });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/listings/SID/${SID}`
    );
    dispatch({
      type: FETCH_CAMPAIGN_BY_SID_SUCCESS,
      payload: response.data,
    });

  } catch (error) {
    dispatch({
      type: FETCH_CAMPAIGN_BY_SID_FAILURE,
      payload: error.response
        ? error.response.data
        : { message: error.message },
    });
  }
};

// Delete a campaign

export const deleteCampaignSuccess = (id) => ({
  type: DELETE_CAMPAIGN_SUCCESS,
  payload: id,
});

export const deleteCampaignFailure = (error) => ({
  type: DELETE_CAMPAIGN_FAILURE,
  payload: error,
});

export const deleteCampaign = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/campaigns/${id}`);
    dispatch(deleteCampaignSuccess(id));
  } catch (error) {
    dispatch(deleteCampaignFailure(error.message));
  }
};

export const createCampaignSuccess = (campaign) => ({
  type: CREATE_CAMPAIGN_SUCCESS,
  payload: campaign,
});

export const createCampaignFailure = (error) => ({
  type: CREATE_CAMPAIGN_FAILURE,
  payload: error,
});

// Thunk action for creating a new campaign
export const createCampaign = (campaignData) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/campaigns/create`,
      campaignData
    );
    return dispatch(createCampaignSuccess(response.data));
  } catch (error) {
    return dispatch(createCampaignFailure(error.message));
  }
};

export const associateListingsSuccess = (updatedCampaign) => ({
  type: ASSOCIATE_LISTINGS_SUCCESS,
  payload: updatedCampaign,
});

export const associateListingsFailure = (error) => ({
  type: ASSOCIATE_LISTINGS_FAILURE,
  payload: error,
});

// Thunk action for associating listings with a campaign
export const associateListingsToCampaign =
  (campaignId, listingIds) => async (dispatch) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/campaigns/associate/${campaignId}`,
        { listingIds } // Send the listings as an array of IDs
      );

      dispatch(associateListingsSuccess(response.data));
    } catch (error) {
      dispatch(associateListingsFailure(error.message));
    }
  };

export const saveMortgageCalculatorData = (id, data) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/campaigns/${id}/mortgage-calculator`,
      data
    );
    dispatch({
      type: SAVE_MORTGAGE_CALCULATOR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: SAVE_MORTGAGE_CALCULATOR_FAILURE,
      payload: error.message,
    });
  }
};
