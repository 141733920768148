import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";

import CustomSnackbar from "./CustomSnackbar"; // Assuming the file is named CustomSnackbar.jsx

import {
  Grid,
  Card,
  CardMedia,
  Container,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchListingByShortId } from "../actions/listingActions";
import { enterContest } from "../actions/contestActions";
import { getListing, getListingLoading } from "../selectors";
import { arrayBufferToBase64, getMonthName } from "../utils/helpers";

const containerStyles = {
  maxWidth: "800px",
  margin: "40px auto",
  padding: "20px",
  backgroundColor: "#f7f7f7",
  border: "1px solid #ddd",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
};
const checkContestStatus = (listing, shortId) => {
  const normalizedShortId = shortId.toLowerCase();

  let hasContestEntry = false;
  let quizCompleted = false;

  if (listing && listing.campaigns) {
    listing.campaigns.forEach((campaign) => {

      if (
        campaign.SID === normalizedShortId &&
        campaign.contestEntry &&
        campaign.contestEntry.date &&
        campaign.contestEntry.contact
      ) {
        hasContestEntry = true;
        quizCompleted = true;
      }
    });
  }

  return {
    quizCompleted: quizCompleted, // Assuming this is determined elsewhere
    contestEntryReceived: hasContestEntry,
  };
};

const LandingPage = () => {
  const dispatch = useDispatch();
  const { shortId } = useParams();
  const listing = useSelector(getListing);
  const isLoading = useSelector(getListingLoading);

  const [yearPurchased, setYearPurchased] = useState("");
  const [monthPurchased, setMonthPurchased] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [yearSold, setYearSold] = useState(0);
  const [yearsSince, setYearsSince] = useState(0);
  const [monthSold, setMonthSold] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [resultsMessage, setResultsMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [totalCorrectCount, setTotalCorrectCount] = useState("");
  const [successfulContestResult, setSuccessfulContestResult] = useState(false);
  const [contestEntryReceived, setContestEntryReceived] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // useEffect(() => {
  //   const fetchListing = async () => {
  //     const response = await dispatch(fetchListingByShortId(shortId));
  //     checkContestStatus(listing);
  //     if (response.error || !response.payload) {
  //       // Adjust based on your actual API response structure
  //       setNotFound(true);
  //     }
  //   };
  //   fetchListing();
  // }, [dispatch, shortId, listing]);

  // useEffect(() => {
  //   dispatch(fetchListingByShortId(shortId));
  // }, [dispatch, shortId]);

  useEffect(() => {
    if (listing?.DateSold) {
      const date = new Date(listing.DateSold);
      const currentYear = new Date().getFullYear(); // Get the current year
      setYearSold(date.getFullYear());
      setMonthSold(date.getMonth() + 1); // getMonth() is zero-based
      setYearsSince(currentYear - date.getFullYear()); // Calculate years since the property was sold
    }
  }, [listing?.DateSold]);

  useEffect(() => {
    const fetchListing = async () => {
      const response = await dispatch(fetchListingByShortId(shortId));
      if (response.error || !response.payload) {
        setNotFound(true);
      } else {
        const { payload } = response;
        setYearSold(new Date(payload.DateSold).getFullYear());
        setMonthSold(new Date(payload.DateSold).getMonth() + 1);

        setYearsSince(
          new Date().getFullYear() - new Date(payload.DateSold).getFullYear()
        );
        const status = checkContestStatus(payload, shortId);
        if (status.contestEntryReceived) {
          setContestEntryReceived(true);
          setSubmitted(true);
        }
      }
    };
    fetchListing();
  }, [dispatch, shortId]);

  const truncatePrice = (price) => Math.floor(price);
  const salePrice = truncatePrice(listing?.PriceSold / 1000);

  const checkAnswers = () => {
    const correctYear = yearSold.toString();
    const correctMonth = getMonthName(monthSold);
    const correctPrice = `${salePrice}K`;

    let correctCount = 0;
    if (yearPurchased === correctYear) correctCount++;
    if (monthPurchased === correctMonth) correctCount++;
    if (purchasePrice === correctPrice) correctCount++;
    if (correctCount === 3) setSuccessfulContestResult(true);
    setTotalCorrectCount(correctCount);

    setResultsMessage(`You got ${correctCount} out of 3 answers correct.`);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const contactData = {
      firstName,
      lastName,
      phoneNumber,
      email,
    };

    try {
      await dispatch(enterContest(shortId, contactData));
      showSnackbar("You have been entered into the draw!", "success");
      handleDialogClose();
    } catch (error) {
      showSnackbar("Failed to enter the draw: " + error.message, "error");
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setOpenDialog(true);
    checkAnswers();
  };

  const image = listing?.image;
  const imageUrl = image?.data
    ? `data:${image.contentType};base64,${arrayBufferToBase64(image.data.data)}`
    : null;

  if (isLoading) {
    return (
      <Container
        maxWidth="md"
        sx={{
          maxWidth: "800px",
          margin: "40px auto",
          padding: "20px",
          backgroundColor: "#f7f7f7",
          border: "1px solid #ddd",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  if (isLoading) {
    return (
      <Container maxWidth="md" sx={containerStyles}>
        <CircularProgress />
      </Container>
    );
  }

  if (notFound) {
    return (
      <Container maxWidth="md" sx={containerStyles}>
        <Typography variant="h4" sx={{ textAlign: "center", mt: 4 }}>
          Oops! The requested listing was not found.
        </Typography>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        maxWidth: "800px",
        mx: "auto", // shorthand for margin-left and margin-right
        p: 2, // shorthand for padding
        backgroundColor: "#f7f7f7",
        border: "2px solid hsl(210deg 76% 47%)",
        mt: 4,
        borderRadius: 2,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Grid container spacing={4} sx={{ alignItems: "center" }}>
        <Grid item xs={12}>
          <Typography
            sx={{ textAlign: "center", fontWeight: 700, fontSize: "1.8rem" }}
          >
            Welcome to Your Home History Quiz!
          </Typography>
          {(contestEntryReceived && (
            <Typography
              sx={{
                color: "#d32f2f",
                backgroundColor: "#ffebee",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              You've already participated in this quiz and entered the contest.{" "}
              <br />
              Wishing you Good Luck! 🍀
            </Typography>
          )) || (
            <Typography>
              Answer the following questions to enter our monthly draw for a $50
              Tim Horton's gift card. <br />
              <br />
              You'll also be entered into the draw for the entire year, giving
              you multiple chances to win! Winners will be announced via email
              each month. <br />
              <br />
              Good luck!
            </Typography>
          )}
        </Grid>
        {imageUrl && (
          <Grid item xs={12}>
            <Card>
              <CardMedia component="img" image={imageUrl} alt="Listing" />
            </Card>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <form
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={12}>
                <Typography
                  sx={{
                    fontWeight: 900,
                    fontSize: "1.5rem",
                    textAlign: "center",
                  }}
                >
                  Do you remember...
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
                <FormControl>
                  <Typography
                    sx={{
                      fontWeight: 800,
                      marginTop: "1.2rem",
                      fontSize: "1.2rem",
                    }}
                  >
                    What year did you buy your home?
                  </Typography>
                  <RadioGroup
                    name="yearPurchased"
                    // value={yearPurchased}
                    value={submitted ? yearSold.toString() : yearPurchased}
                    onChange={(e) => setYearPurchased(e.target.value)}
                    sx={{
                      marginBottom: "10px",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {[yearSold - 1, yearSold, yearSold + 1].map((year) => (
                      <FormControlLabel
                        key={year}
                        value={year.toString()}
                        control={<Radio />}
                        label={year}
                        sx={{
                          marginBottom: "10px",
                          color:
                            submitted && year === yearSold
                              ? "green"
                              : "inherit",
                          bgcolor:
                            submitted && year === yearSold
                              ? "lightyellow"
                              : "inherit",
                          p: 1,
                        }}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
                <FormControl>
                  <Typography
                    sx={{
                      fontWeight: 800,
                      marginTop: "1.2rem",
                      fontSize: "1.2rem",
                    }}
                  >
                    What month did you buy your home?
                  </Typography>
                  <RadioGroup
                    name="monthPurchased"
                    // value={monthPurchased}
                    value={submitted ? getMonthName(monthSold) : monthPurchased}
                    onChange={(e) => setMonthPurchased(e.target.value)}
                    sx={{
                      marginBottom: "10px",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {[monthSold - 1, monthSold, monthSold + 1].map((month) => (
                      <FormControlLabel
                        key={month}
                        value={getMonthName(month)}
                        control={<Radio />}
                        label={getMonthName(month)}
                        sx={{
                          marginBottom: "10px",
                          color:
                            submitted &&
                            getMonthName(month) === getMonthName(monthSold)
                              ? "green"
                              : "inherit",
                          bgcolor:
                            submitted &&
                            getMonthName(month) === getMonthName(monthSold)
                              ? "lightyellow"
                              : "inherit",
                          p: 1,
                        }}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
                <FormControl>
                  <Typography
                    sx={{
                      fontWeight: 800,
                      marginTop: "1.2rem",
                      fontSize: "1.2rem",
                    }}
                  >
                    How much did you pay for your home?
                  </Typography>
                  <RadioGroup
                    name="purchasePrice"
                    // value={purchasePrice}
                    value={submitted ? `${salePrice}K` : purchasePrice}
                    onChange={(e) => setPurchasePrice(e.target.value)}
                    sx={{
                      marginBottom: "10px",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {[0.98, 1, 1.02, 1.05].map((factor) => {
                      const price = `${truncatePrice(salePrice * factor)}K`;
                      return (
                        <FormControlLabel
                          key={factor}
                          value={price}
                          control={<Radio />}
                          label={price}
                          sx={{
                            marginBottom: "10px",
                            color:
                              submitted && price === `${salePrice}K`
                                ? "green"
                                : "inherit",
                            bgcolor:
                              submitted && price === `${salePrice}K`
                                ? "lightyellow"
                                : "inherit",
                            p: 1,
                          }}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
                {!contestEntryReceived && (
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: "#4caf50",
                      color: "#fff",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      "&:hover": {
                        // Hover state styles
                        backgroundColor: "#3e8e41",
                      },
                    }}
                    variant="contained"
                    color="primary"
                    disabled={
                      !yearPurchased || !monthPurchased || !purchasePrice
                    }
                  >
                    Submit Quiz
                  </Button>
                )}
                {submitted && (
                  <Typography sx={{ mt: 2, color: "blue" }}>
                    {resultsMessage}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        sx={{ "& .MuiDialog-container": { alignItems: "flex-start" } }}
      >
        <DialogTitle sx={{ fontWeight: 900, textAlign: "Center" }}>
          {" "}
          {successfulContestResult
            ? "🏆 Congratulations You Are Right! 🏆 "
            : totalCorrectCount === 0
            ? "🚫 Whoops...Missed them all!"
            : totalCorrectCount === 1
            ? "✅ You managed to get one right!"
            : "🔶 So close... 2 out of 3 Correct"}
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{ fontWeight: 700, textAlign: "Center", marginBottom: 2 }}
          >
            {successfulContestResult ? "Impressive since " : "Mind you  "} that
            was {yearsSince} years ago... 😆
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Correct Answers:
          </Typography>
          <Typography>
            <span sx={{ fontWeight: 700 }}>Year Purchased:</span> {yearSold}
          </Typography>
          <Typography>
            <span sx={{ fontWeight: 700 }}>Month Purchased:</span>{" "}
            {getMonthName(monthSold)}
          </Typography>
          <Typography>
            <span sx={{ fontWeight: 700 }}>Purchase Price:</span> ${salePrice}K
            (rounded down to nearest thousand)
          </Typography>
          <Typography sx={{ marginTop: 2, marginBottom: 2 }}>
            {successfulContestResult
              ? "Congratulations on getting the questions all right! You've earned a spot in our "
              : "Even though you weren't correct you are still invited to join the "}
            monthly draw for a $50 Tim Horton's gift card, and you'll remain in
            the draw for the entire year. This means you could win in any month
            from now until the end of 2024! Winners will be announced via email
            each month. Remember, there's a limit of one entry per household.
            Good luck!
          </Typography>
          <Typography sx={{ fontWeight: "bolder" }}>
            Complete and Submit the form below to enter:
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="firstName"
            label="First Name"
            type="text"
            fullWidth
            variant="outlined"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            margin="dense"
            id="lastName"
            label="Last Name"
            type="text"
            fullWidth
            variant="outlined"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            margin="dense"
            id="phoneNumber"
            label="Phone Number (Optional)"
            type="tel"
            fullWidth
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            required
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={handleDialogClose} color="error">
            Cancel
          </Button>
          <Button
            onClick={handleFormSubmit}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Container>
  );
};

export default LandingPage;
