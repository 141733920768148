import React, { useState } from "react";
import { getCityFromPostalCode } from "../utils/postalCodeLookup";
const PostalCodeLookup = () => {
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [error, setError] = useState("");

  const handleLookup = async () => {
    try {
      
      const { city: cityName, province } = await getCityFromPostalCode(
        postalCode
      );
      setCity(cityName);
      setProvince(province);
      setError("");
    } catch (err) {
      setError(err.message);
      setCity("");
    }
  };

  return (
    <div>
      <h1>Postal Code Lookup</h1>
      <input
        type="text"
        value={postalCode}
        onChange={(e) => setPostalCode(e.target.value)}
        placeholder="Enter postal code"
      />
      <button onClick={handleLookup}>Lookup City</button>
      {city && (
        <p>
          City: {city}, Province: {province}
        </p>
      )}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default PostalCodeLookup;
