// src/utils/server.js

export const sendListingDataToServer = async (data, signal) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/listings/add`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        signal,
      }
    );

    return response.ok;
  } catch (error) {
    console.error("Error sending data to server:", error);
    return false;
  }
};

export const sendAgentDataToServer = async (agentData, signal) => {
  if (!agentData.name || agentData.name.trim() === "") {
    console.error("Error: Agent name is empty");
    return null;
  }
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/agents/add`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(agentData),
        signal,
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data.agent._id;
    } else {
      throw new Error("Failed to create or update agent");
    }
  } catch (error) {
    console.error("Error sending agent data to server:", error);
    return null;
  }
};

export const fetchRecords = async (
  searchTerm,
  filterDate,
  currentPage,
  recordsPerPage
) => {
  const params = new URLSearchParams({
    searchTerm,
    month: filterDate.month,
    year: filterDate.year,
    page: currentPage,
    recordsPerPage,
  }).toString();

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/listings/list?${params}`
  );
  const data = await response.json();

  if (data.records && Array.isArray(data.records)) {
    return data;
  } else {
    throw new Error("Invalid response format");
  }
};

export const fetchLatestListing = async (address, signal) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/listings/latest/${address}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        signal,
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch latest listing");
    }
  } catch (error) {
    console.error("Error fetching the latest listing:", error);
    return null;
  }
};

export const sendHomeValuationRequestToServer = async (
  valuationData,
  signal
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/lpr/homeValuationRequest`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(valuationData),
        signal,
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data; // Adjust as per your backend response structure
    } else {
      throw new Error("Failed to submit home valuation");
    }
  } catch (error) {
    console.error("Error sending home valuation data to server:", error);
    return null;
  }
};

export const sendHomeSearchRequestToServer = async (
  homeSearchRequestData,
  signal
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/lpr/homeSearchRequest`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(homeSearchRequestData),
        signal,
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data; // Adjust as per your backend response structure
    } else {
      throw new Error("Failed to submit home search request");
    }
  } catch (error) {
    console.error("Error sending home search request data to server:", error);
    return null;
  }
};

export const submitQuestionToServer = async (questionData, signal) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/lpr/askMeAnything`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(questionData),
        signal,
      }
    );
    console.log("response", response);
    if (response.ok) {
      const data = await response.json();
      return data; // Adjust as per your backend response structure
    } else {
      throw new Error("Failed to submit question");
    }
  } catch (error) {
    console.error("Error sending question data to server:", error);
    return null;
  }
};
