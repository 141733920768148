export const propertySubTypes = [
  "Condo Apartment",
  "Full Duplex",
  "Half Duplex",
  "Land",
  "Manufactured Home",
  "Other",
  "PropertySubType",
  "Quadruplex",
  "Recreational",
  "Row/Townhouse",
  "Single Family Detached",
  "Triplex",
];

export const areas = [
  { name: "Campbell River", code: "CR" },
  { name: "Central Saanich", code: "CS" },
  { name: "Colwood", code: "Co" },
  { name: "Comox Valley", code: "CV" },
  { name: "Duncan", code: "Du" },
  { name: "Esquimalt", code: "Es" },
  { name: "Gulf Islands", code: "GI" },
  { name: "Highlands", code: "Hi" },
  { name: "Islands", code: "Isl" },
  { name: "Langford", code: "La" },
  { name: "Mainland", code: "Mn" },
  { name: "Malahat & Area", code: "ML" },
  { name: "Metchosin", code: "Me" },
  { name: "Nanaimo", code: "Na" },
  { name: "North Island", code: "NI" },
  { name: "North Saanich", code: "NS" },
  { name: "Oak Bay", code: "OB" },
  { name: "Other Boards", code: "Other" },
  { name: "Parksville/Qualicum", code: "PQ" },
  { name: "Port Alberni", code: "PA" },
  { name: "Saanich East", code: "SE" },
  { name: "Saanich West", code: "SW" },
  { name: "Sidney", code: "Si" },
  { name: "Sooke", code: "Sk" },
  { name: "Victoria", code: "Vi" },
  { name: "Victoria West", code: "VW" },
  { name: "View Royal", code: "VR" },
];

export const subAreas = [
  { name: "Co Colwood Corners", city: "Victoria" },
  { name: "Co Colwood Lake", city: "Victoria" },
  { name: "Co Hatley Park", city: "Victoria" },
  { name: "Co Lagoon", city: "Victoria" },
  { name: "Co Latoria", city: "Victoria" },
  { name: "Co Olympic View", city: "Victoria" },
  { name: "Co Royal Bay", city: "Victoria" },
  { name: "Co Royal Roads", city: "Victoria" },
  { name: "Co Sun Ridge", city: "Victoria" },
  { name: "Co Triangle", city: "Victoria" },
  { name: "Co Wishart North", city: "Victoria" },
  { name: "Co Wishart South", city: "Victoria" },
  { name: "CR Campbell River Central", city: "Campbell River" },
  { name: "CR Campbell River North", city: "Campbell River" },
  { name: "CR Campbell River South", city: "Campbell River" },
  { name: "CR Campbell River West", city: "Campbell River" },
  { name: "CR Campbellton", city: "Campbell River" },
  { name: "CR Willow Point", city: "Campbell River" },
  { name: "CS Brentwood Bay", city: "Brentwood Bay" },
  { name: "CS Hawthorne", city: "Sannichton" },
  { name: "CS Inlet", city: "Sannichton" },
  { name: "CS Island View", city: "Sannichton" },
  { name: "CS Keating", city: "Sannichton" },
  { name: "CS Martindale", city: "Sannichton" },
  { name: "CS Oldfield", city: "Sannichton" },
  { name: "CS Saanichton", city: "Sannichton" },
  { name: "CS Tanner", city: "Victoria" },
  { name: "CS Turgoose", city: "Sannichton" },
  { name: "CS Willis Point", city: "Victoria" },
  { name: "CV Comox (Town of)", city: "Comox" },
  { name: "CV Comox Peninsula", city: "Comox" },
  { name: "CV Courtenay City", city: "Courtenay" },
  { name: "CV Courtenay East", city: "Courtenay" },
  { name: "CV Courtenay North", city: "Courtenay" },
  { name: "CV Courtenay South", city: "Courtenay" },
  { name: "CV Courtenay West", city: "Courtenay" },
  { name: "CV Crown Isle", city: "Courtenay" },
  { name: "CV Cumberland", city: "Cumberland" },
  { name: "CV Merville Black Creek", city: "Black Creek" },
  { name: "CV Mt Washington", city: "Courtenay" },
  { name: "CV Union Bay/Fanny Bay", city: "Fanny Bay" },
  { name: "Du Chemainus", city: "Chemainus" },
  { name: "Du Cowichan Bay", city: "Cowichan Bay" },
  { name: "Du Cowichan Station/Glenora", city: "Duncan" },
  { name: "Du Crofton", city: "PO BOX" },
  { name: "Du East Duncan", city: "Duncan" },
  { name: "Du Honeymoon Bay", city: "PO BOX" },
  { name: "Du Ladysmith", city: "Ladysmith" },
  { name: "Du Lake Cowichan", city: "Lake Cowichan" },
  { name: "Du Saltair", city: "Ladysmith" },
  { name: "Du West Duncan", city: "Duncan" },
  { name: "Du Youbou", city: "Youbou" },
  { name: "Es Esquimalt", city: "Esquimalt" },
  { name: "Es Gorge Vale", city: "Esquimalt" },
  { name: "Es Kinsmen Park", city: "Esquimalt" },
  { name: "Es Old Esquimalt", city: "Esquimalt" },
  { name: "Es Rockheights", city: "Esquimalt" },
  { name: "Es Saxe Point", city: "Esquimalt" },
  { name: "GI Galiano", city: "Galiano Island" },
  { name: "GI Gulf Isl Other", city: "PO BOX" },
  { name: "GI Mayne Island", city: "Mayne Island" },
  { name: "GI Moresby Island", city: "PO BOX" },
  { name: "GI Pender Island", city: "Pender Island" },
  { name: "GI Piers Island", city: "PO BOX" },
  { name: "GI Salt Spring", city: "Salt Spring island" },
  { name: "GI Saturna Island", city: "PO BOX" },
  { name: "GI Sidney Island", city: "PO BOX" },
  { name: "Hi Bear Mountain", city: "Victoria" },
  { name: "Hi Eastern Highlands", city: "Victoria" },
  { name: "Hi Western Highlands", city: "Victoria" },
  { name: "Isl Alert Bay", city: "PO BOX" },
  { name: "Isl Cortes Island", city: "PO BOX" },
  { name: "Isl Decourcey Island", city: "PO BOX" },
  { name: "Isl Denman Island", city: "Denman Island" },
  { name: "Isl Gabriola Island", city: "Gabriola Island" },
  { name: "Isl Hornby Island", city: "Hornby Island" },
  { name: "Isl Lasqueti Island", city: "PO BOX" },
  { name: "Isl Mudge Island", city: "PO BOX" },
  { name: "Isl Protection Island", city: "Nanaimo" },
  { name: "Isl Quadra Island", city: "PO BOX" },
  { name: "Isl Ruxton Island", city: "PO BOX" },
  { name: "Isl Small Islands (Campbell River Area)", city: "PO BOX" },
  { name: "Isl Small Islands (North Island Area)", city: "PO BOX" },
  { name: "Isl Sointula", city: "PO BOX" },
  { name: "Isl Thetis Island", city: "PO BOX" },
  { name: "La Atkins", city: "Langford" },
  { name: "La Bear Mountain", city: "Langford" },
  { name: "La Fairway", city: "Langford" },
  { name: "La Florence Lake", city: "Langford" },
  { name: "La Glen Lake", city: "Langford" },
  { name: "La Goldstream", city: "Langford" },
  { name: "La Happy Valley", city: "Langford" },
  { name: "La Humpback", city: "Langford" },
  { name: "La Jacklin", city: "Langford" },
  { name: "La Langford Lake", city: "Langford" },
  { name: "La Langford Proper", city: "Langford" },
  { name: "La Luxton", city: "Langford" },
  { name: "La Mill Hill", city: "Langford" },
  { name: "La Olympic View", city: "Victoria" },
  { name: "La Thetis Heights", city: "Langford" },
  { name: "La Walfred", city: "Langford" },
  { name: "La Westhills", city: "Langford" },
  { name: "Me Albert Head", city: "Victoria" },
  { name: "Me Kangaroo", city: "Victoria" },
  { name: "Me Metchosin", city: "Victoria" },
  { name: "Me Neild", city: "Victoria" },
  { name: "Me Olympic View", city: "Victoria" },
  { name: "Me Pedder Bay", city: "Victoria" },
  { name: "Me Rocky Point", city: "Victoria" },
  { name: "Me William Head", city: "Victoria" },
  { name: "ML Cobble Hill", city: "Cobble Hill" },
  { name: "ML Malahat Proper", city: "Shawnigan Lake" },
  { name: "ML Mill Bay", city: "Mill Bay" },
  { name: "ML Shawnigan", city: "Shawnigan Lake" },
  { name: "Mn Mainland Proper", city: "N/A" },
  { name: "Na Brechin Hill", city: "Nanaimo" },
  { name: "Na Cedar", city: "Cedar" },
  { name: "Na Central Nanaimo", city: "Nanaimo" },
  { name: "Na Chase River", city: "Nanaimo" },
  { name: "Na Departure Bay", city: "Nanaimo" },
  { name: "Na Diver Lake", city: "Nanaimo" },
  { name: "Na Extension", city: "Nanaimo" },
  { name: "Na Hammond Bay", city: "Nanaimo" },
  { name: "Na Lower Lantzville", city: "Lantzville" },
  { name: "Na North Jingle Pot", city: "Nanaimo" },
  { name: "Na North Nanaimo", city: "Nanaimo" },
  { name: "Na Old City", city: "Nanaimo" },
  { name: "Na Pleasant Valley", city: "Nanaimo" },
  { name: "Na South Jingle Pot", city: "Nanaimo" },
  { name: "Na South Nanaimo", city: "Nanaimo" },
  { name: "Na University District", city: "Nanaimo" },
  { name: "Na Uplands", city: "Nanaimo" },
  { name: "Na Upper Lantzville", city: "Lantzville" },
  { name: "NI Gold River", city: "PO BOX" },
  { name: "NI Kelsey Bay/Sayward", city: "Sayward" },
  { name: "NI Port Alice", city: "PO BOX" },
  { name: "NI Port Hardy", city: "PO BOX" },
  { name: "NI Port McNeill", city: "PO BOX" },
  { name: "NI Tahsis/Zeballos", city: "PO BOX" },
  { name: "NI Woss", city: "PO BOX" },
  { name: "NS Airport", city: "North Sannich" },
  { name: "NS Ardmore", city: "North Sannich" },
  { name: "NS Bazan Bay", city: "North Sannich" },
  { name: "NS Curteis Point", city: "North Sannich" },
  { name: "NS Dean Park", city: "North Sannich" },
  { name: "NS Deep Cove", city: "North Sannich" },
  { name: "NS Lands End", city: "North Sannich" },
  { name: "NS McDonald Park", city: "North Sannich" },
  { name: "NS Sandown", city: "North Sannich" },
  { name: "NS Swartz Bay", city: "North Sannich" },
  { name: "OB Estevan", city: "Victoria" },
  { name: "OB Gonzales", city: "Victoria" },
  { name: "OB Henderson", city: "Victoria" },
  { name: "OB North Oak Bay", city: "Victoria" },
  { name: "OB South Oak Bay", city: "Victoria" },
  { name: "OB Uplands", city: "Victoria" },
  { name: "Other Boards", city: "PO BOX" },
  { name: "PA Alberni Inlet", city: "Port Alberni" },
  { name: "PA Alberni Valley", city: "Port Alberni" },
  { name: "PA Port Alberni", city: "Port Alberni" },
  { name: "PA Salmon Beach", city: "PO BOX" },
  { name: "PA Sproat Lake", city: "Port Alberni" },
  { name: "PA Tofino", city: "PO BOX" },
  { name: "PA Ucluelet", city: "PO BOX" },
  { name: "PQ Bowser/Deep Bay", city: "Bowser" },
  { name: "PQ Errington/Coombs/Hilliers", city: "Qualicum Beach" },
  { name: "PQ Fairwinds", city: "Nanoose Bay" },
  { name: "PQ French Creek", city: "Parksville" },
  { name: "PQ Little Qualicum River Village", city: "Qualicum Beach" },
  { name: "PQ Nanoose", city: "Nanoose Bay" },
  { name: "PQ Parksville", city: "Parksville" },
  { name: "PQ Qualicum Beach", city: "Qualicum Beach" },
  { name: "PQ Qualicum North", city: "Qualicum Beach" },
  { name: "SE Arbutus", city: "Victoria" },
  { name: "SE Blenkinsop", city: "Victoria" },
  { name: "SE Broadmead", city: "Victoria" },
  { name: "SE Cadboro Bay", city: "Victoria" },
  { name: "SE Camosun", city: "Victoria" },
  { name: "SE Cedar Hill", city: "Victoria" },
  { name: "SE Cordova Bay", city: "Victoria" },
  { name: "SE Gordon Head", city: "Victoria" },
  { name: "SE High Quadra", city: "Victoria" },
  { name: "SE Lake Hill", city: "Victoria" },
  { name: "SE Lambrick Park", city: "Victoria" },
  { name: "SE Maplewood", city: "Victoria" },
  { name: "SE Mt Doug", city: "Victoria" },
  { name: "SE Mt Tolmie", city: "Victoria" },
  { name: "SE Quadra", city: "Victoria" },
  { name: "SE Queenswood", city: "Victoria" },
  { name: "SE Sunnymead", city: "Victoria" },
  { name: "SE Swan Lake", city: "Victoria" },
  { name: "SE Ten Mile Point", city: "Victoria" },
  { name: "Si Sidney North-East", city: "Sidney" },
  { name: "Si Sidney North-West", city: "Sidney" },
  { name: "Si Sidney South-East", city: "Sidney" },
  { name: "Si Sidney South-West", city: "Sidney" },
  { name: "Sk 17 Mile", city: "Sooke" },
  { name: "Sk Becher Bay", city: "Sooke" },
  { name: "Sk Billings Spit", city: "Sooke" },
  { name: "Sk Broomhill", city: "Sooke" },
  { name: "Sk East Sooke", city: "Sooke" },
  { name: "Sk French Beach", city: "Shirley" },
  { name: "Sk John Muir", city: "Sooke" },
  { name: "Sk Jordan River", city: "Jordan River" },
  { name: "Sk Kemp Lake", city: "Sooke" },
  { name: "Sk Otter Point", city: "Sooke" },
  { name: "Sk Port Renfrew", city: "PO BOX" },
  { name: "Sk Saseenos", city: "Sooke" },
  { name: "Sk Sheringham Pnt", city: "Shirley" },
  { name: "Sk Silver Spray", city: "Sooke" },
  { name: "Sk Sooke River", city: "Sooke" },
  { name: "Sk Sooke Vill Core", city: "Sooke" },
  { name: "Sk Sunriver", city: "Sooke" },
  { name: "Sk West Coast Rd", city: "Sooke" },
  { name: "Sk Whiffin Spit", city: "Sooke" },
  { name: "SW Beaver Lake", city: "Victoria" },
  { name: "SW Elk Lake", city: "Victoria" },
  { name: "SW Gateway", city: "Victoria" },
  { name: "SW Glanford", city: "Victoria" },
  { name: "SW Gorge", city: "Victoria" },
  { name: "SW Granville", city: "Victoria" },
  { name: "SW Interurban", city: "Victoria" },
  { name: "SW Layritz", city: "Victoria" },
  { name: "SW Marigold", city: "Victoria" },
  { name: "SW Northridge", city: "Victoria" },
  { name: "SW Portage Inlet", city: "Victoria" },
  { name: "SW Prospect Lake", city: "Victoria" },
  { name: "SW Royal Oak", city: "Victoria" },
  { name: "SW Rudd Park", city: "Victoria" },
  { name: "SW Strawberry Vale", city: "Victoria" },
  { name: "SW Tillicum", city: "Victoria" },
  { name: "SW West Saanich", city: "Victoria" },
  { name: "Vi Burnside", city: "Victoria" },
  { name: "Vi Central Park", city: "Victoria" },
  { name: "Vi Downtown", city: "Victoria" },
  { name: "Vi Fairfield East", city: "Victoria" },
  { name: "Vi Fairfield West", city: "Victoria" },
  { name: "Vi Fernwood", city: "Victoria" },
  { name: "Vi Hillside", city: "Victoria" },
  { name: "Vi James Bay", city: "Victoria" },
  { name: "Vi Jubilee", city: "Victoria" },
  { name: "Vi Mayfair", city: "Victoria" },
  { name: "Vi Oaklands", city: "Victoria" },
  { name: "Vi Rock Bay", city: "Victoria" },
  { name: "Vi Rockland", city: "Victoria" },
  { name: "VR Glentana", city: "Victoria" },
  { name: "VR Hospital", city: "Victoria" },
  { name: "VR Prior Lake", city: "Victoria" },
  { name: "VR Six Mile", city: "Victoria" },
  { name: "VR View Royal", city: "Victoria" },
  { name: "VW Songhees", city: "Victoria" },
  { name: "VW Victoria West", city: "Victoria" },
];
