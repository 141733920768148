import React from "react";
import { Box, TextField, Button } from "@mui/material";
import { connect } from "react-redux";
import { updateCampaign } from "../actions/campaignActions";

const CampaignEditForm = ({
  campaign,
  onChange,
  onSave,
  onDeleteAllListings,
  updateCampaign,
}) => {
  const {
    _id = "",
    name = "",
    campaignDate = "",
    status = "",
    vendor = "",
    totalCost = "",
    quantityMailed = "",
    responseRate = "",
    responsesReceived = "",
    notes = "",
    objectives = "",
    listings = [],
  } = campaign;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Create a new object excluding listings
      const { listings, ...campaignDetails } = campaign;
      await updateCampaign(campaign._id, campaignDetails);
      onSave();
    } catch (error) {
      console.error("Error saving campaign", error);
    }
  };

  return (
    <Box className="campaign-form" component="form" onSubmit={handleSubmit}>
      <TextField
        label="Name"
        name="name"
        value={name}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Date"
        name="campaignDate"
        type="date"
        value={campaignDate.split("T")[0]} // Format date to yyyy-MM-dd
        onChange={handleInputChange}
        fullWidth
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Status"
        name="status"
        value={status}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Vendor"
        name="vendor"
        value={vendor}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Cost"
        name="totalCost"
        type="number"
        value={totalCost}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Quantity Mailed"
        name="quantityMailed"
        type="number"
        value={quantityMailed}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Response Rate"
        name="responseRate"
        type="number"
        value={responseRate}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Responses Received"
        name="responsesReceived"
        type="number"
        value={responsesReceived}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Notes"
        name="notes"
        value={notes}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Objectives"
        name="objectives"
        value={objectives}
        onChange={handleInputChange}
        fullWidth
      />
      {listings && listings.length > 0 && (
        <Button
          variant="contained"
          color="secondary"
          onClick={onDeleteAllListings}
          sx={{ marginLeft: 2 }}
        >
          Remove All Listings
        </Button>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ marginTop: 2 }}
      >
        Save
      </Button>
    </Box>
  );
};

const mapDispatchToProps = {
  updateCampaign,
};

export default connect(null, mapDispatchToProps)(CampaignEditForm);
