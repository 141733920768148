import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Stack,
  Slider,
  Grid,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import CloseIcon from "@mui/icons-material/Close";
import { sendHomeValuationRequestToServer } from "../utils/services"; // Update the path as per your project structure

const HomeValuation = ({ SID, campaignId }) => {
  const [open, setOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [homeRating, setHomeRating] = useState(5);
  const [improvementPlans, setImprovementPlans] = useState("");
  const [valuationType, setValuationType] = useState("non-contact");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [preferredContact, setPreferredContact] = useState("phone");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [bedrooms, setBedrooms] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  const [sqft, setSqft] = useState("");
  const [hasSuite, setHasSuite] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotificationClose = () => setNotificationOpen(false);

  const handleSubmit = async () => {
    const formData = {
      firstName,
      lastName,
      homeRating,
      improvementPlans,
      valuationType,
      additionalInfo,
      address,
      city,
      postalCode,
      preferredContact,
      phoneNumber,
      email,
      bedrooms,
      bathrooms,
      sqft,
      hasSuite,
      campaignId,
    };

    const success = await sendHomeValuationRequestToServer(formData);
    if (success) {
      setNotificationMessage(
        "Home Valuation Request has been submitted successfully! You will hear back from listing specialists from The Dan Morris Real Estate Team shortly."
      );
      setNotificationOpen(true);
      handleClose(); // Close modal after successful submission
    } else {
      setNotificationMessage(
        "Failed to submit form. \n\nPlease try again later."
      );
      setNotificationOpen(true);
    }
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        sx={{
          border: "2px solid hsl(210deg 76% 47%)",
          borderRadius: "8px",
          p: 4,
          textAlign: "center",
          bgcolor: "white",
          width: "100%",
          mx: "auto",
          mt: 4,
          cursor: "pointer", // Add cursor style for mouse icon
        }}
      >
        <Typography variant="h5" gutterBottom>
          Home Valuation
        </Typography>
        <Typography variant="body1">
          Get a free home valuation report with no obligations. Tell us about
          your home and we'll provide you with a detailed report.
        </Typography>
        <Stack direction="row" justifyContent="center" mt={2}>
          <IconButton
            onClick={handleOpen}
            color="primary"
            aria-label="home valuation"
            size="large"
          >
            <HomeIcon fontSize="large" />
          </IconButton>
        </Stack>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: 800,
            maxHeight: "80vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              width: "100%",
              bgcolor: "hsl(210deg 76% 47%)",
              pl: 2,
              pr: 2,
              pt: 1,
              pb: 1,
              mb: 1,
              display: "flex",
              justifyContent: "space-between", // Center align horizontally
              alignItems: "stretch", // Align items vertically if needed
            }}
          >
            <HomeIcon fontSize="large" sx={{ color: "white" }} />
            <Typography
              variant="h5"
              component="h2"
              fontWeight={"bold"}
              color={"white"}
              sx={{ ml: 1 }} // Adjust margin left for spacing
            >
              Free Home Valuation
            </Typography>
            <IconButton
              onClick={() => handleClose()}
              sx={{ float: "right", color: "white" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography gutterBottom>
            Tell us about your home and we'll provide you with a detailed
            report. We can provide a non-contact valuation or a contact
            valuation.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Postal Code"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  bgcolor: "rgba(0, 0, 0, 0.02)", // Lighter background color
                  border: "1px solid rgba(0, 0, 0, 0.6)", // Lighter border color
                  borderRadius: 4,
                  p: 2,
                  marginBottom: 2,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  About Your Home
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Number of Bedrooms"
                      type="number"
                      value={bedrooms}
                      onChange={(e) => setBedrooms(e.target.value)}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Number of Bathrooms"
                      type="number"
                      value={bathrooms}
                      onChange={(e) => setBathrooms(e.target.value)}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Approximate Size (sqft)"
                      type="number"
                      value={sqft}
                      onChange={(e) => setSqft(e.target.value)}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Do you have a suite?
                      </FormLabel>
                      <RadioGroup
                        aria-label="has-suite"
                        name="has-suite"
                        value={hasSuite.toString()} // Convert boolean to string for radio group
                        onChange={(e) => setHasSuite(e.target.value === "true")}
                        row // Display radios horizontally
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom>
                How would you rank your home on a scale of 1-10?
              </Typography>
              <Slider
                value={homeRating}
                onChange={(e, newValue) => setHomeRating(newValue)}
                aria-labelledby="home-rating-slider"
                valueLabelDisplay="auto"
                step={1}
                marks
                min={1}
                max={10}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="If you were going to stay in your home for 5 more years, what would you change and why?"
                multiline
                rows={4}
                value={improvementPlans}
                onChange={(e) => setImprovementPlans(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Anything else you'd like to share about your home?"
                multiline
                rows={4}
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Valuation Type</FormLabel>
                <RadioGroup
                  aria-label="valuation-type"
                  name="valuation-type"
                  value={valuationType}
                  onChange={(e) => setValuationType(e.target.value)}
                >
                  <FormControlLabel
                    value="non-contact"
                    control={<Radio />}
                    label="Non-contact Valuation"
                  />
                  <FormControlLabel
                    value="contact"
                    control={<Radio />}
                    label="Contact Valuation"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Preferred Method of Contact
                </FormLabel>
                <RadioGroup
                  aria-label="preferred-contact"
                  name="preferred-contact"
                  value={preferredContact}
                  onChange={(e) => setPreferredContact(e.target.value)}
                >
                  <FormControlLabel
                    value="phone"
                    control={<Radio />}
                    label="Phone"
                  />
                  <FormControlLabel
                    value="email"
                    control={<Radio />}
                    label="Email"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                required
              />
            </Grid>
          </Grid>
          <Grid container justify="space-between" mt={2}>
            <Grid item xs={5}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={5}>
              <Button
                onClick={handleClose}
                variant="contained"
                color="error"
                fullWidth
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={notificationOpen}
        onClose={handleNotificationClose}
        aria-labelledby="notification-modal"
        aria-describedby="notification-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="notification-modal-title"
            variant="h5"
            fontWeight="bold"
            component="h2"
          >
            Notification
          </Typography>
          <Typography id="notification-modal-description" sx={{ mt: 2 }}>
            {notificationMessage}
          </Typography>
          <Button
            onClick={handleNotificationClose}
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
            fullWidth
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default HomeValuation;
