import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { submitQuestionToServer } from "../utils/services.js";

const AskMeAnything = ({ SID, campaignId }) => {
  const [questionData, setQuestionData] = useState({
    question: "",
    name: "",
    email: "",
    campaignId,
  });
  const [notificationOpen, setNotificationOpen] = useState(false); // State to manage notification modal visibility
  const [notificationMessage, setNotificationMessage] = useState(""); // State to hold notification message

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await submitQuestionToServer(questionData);
      if (response) {
        // Handle success, e.g., show confirmation modal
        setNotificationMessage("Question submitted successfully!");
        setNotificationOpen(true);
        setQuestionData({ question: "", name: "", email: "", campaignId });
      } else {
        // Handle failure, e.g., show error message to user
        alert("Failed to submit question. Please try again later.");
      }
    } catch (error) {
      // Handle unexpected errors, e.g., show generic error message
      console.error("Error submitting question:", error);
      alert("Failed to submit question. Please try again later.");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setQuestionData({
      ...questionData,
      [name]: value,
    });
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  return (
    <Box
      sx={{
        border: "2px solid hsl(210deg 76% 47%)",
        borderRadius: "8px",
        p: 2,
        mt: 1,
        bgcolor: "white",
      }}
    >
      <Typography sx={{ textAlign: "center" }} variant="h5" gutterBottom>
        Ask Me Anything
      </Typography>
      <Typography variant="body1">
        Have questions about real estate? Ask me anything!
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          id="question"
          name="question"
          label="Your Question"
          multiline
          rows={4}
          fullWidth
          margin="normal"
          value={questionData.question}
          onChange={handleChange}
        />
        <TextField
          id="name"
          name="name"
          label="Your Name"
          fullWidth
          margin="normal"
          value={questionData.name}
          onChange={handleChange}
        />
        <TextField
          id="email"
          name="email"
          label="Your Email"
          type="email"
          fullWidth
          margin="normal"
          value={questionData.email}
          onChange={handleChange}
        />
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </form>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1">
          Contact The Dan Morris Real Estate Team
        </Typography>
        <Typography variant="body1">
          <EmailIcon /> <a href="mailto:dan@danmorris.ca">dan@danmorris.ca</a>
        </Typography>
        <Typography variant="body1">
          <PhoneIcon /> <a href="tel:2506674585">txt or call (250) 667-4585</a>
        </Typography>
      </Box>

      {/* Notification Modal */}
      <Modal
        open={notificationOpen}
        onClose={handleNotificationClose}
        aria-labelledby="notification-modal"
        aria-describedby="notification-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={notificationOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              textAlign: "center",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Notification
            </Typography>
            <Typography variant="body1" id="notification-modal-description">
              {notificationMessage}
            </Typography>
            <Button
              onClick={handleNotificationClose}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Close
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default AskMeAnything;
