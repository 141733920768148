import axios from "axios";

export const fetchAnalysisResults =
  ({
    years,
    yearsMax,
    area,
    subArea,
    propertySubType,
    street,
    statuses,
    yearsInactive,
    limit,
  }) =>
  async (dispatch) => {
    dispatch(fetchAnalysisResultsStart()); // Dispatch start right when fetch begins
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/analysis`,
        {
          params: {
            years,
            yearsMax,
            area,
            subArea,
            propertySubType,
            street,
            statuses,
            yearsInactive,
            limit,
          },
        }
      );
      dispatch(fetchAnalysisResultsSuccess(response.data));
    } catch (error) {
      console.error("Error fetching analysis results:", error);
      // Dispatch the failure action to update the state accordingly
      dispatch(fetchAnalysisResultsFailure());
    }
  };

// Action to start the fetch
export const fetchAnalysisResultsStart = () => ({
  type: "FETCH_ANALYSIS_RESULTS_START",
});

// Action for successful fetch
export const fetchAnalysisResultsSuccess = (data) => ({
  type: "FETCH_ANALYSIS_RESULTS_SUCCESS",
  payload: data,
});

// Action for failed fetch
export const fetchAnalysisResultsFailure = () => ({
  type: "FETCH_ANALYSIS_RESULTS_FAILURE",
});
