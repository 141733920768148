import React, { useState } from "react";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import QRCode from "qrcode";
import {
  getCityFromPostalCode,
  getPostalCodeFromAddress,
} from "../utils/postalCodeLookup";
import { getMonthName } from "../utils/helpers";
import { useDispatch } from "react-redux";

// Registering the fonts (commented out as per the working version)
Font.register({
  family: "Lobster",
  src: `${window.location.origin}/fonts/Lobster-Regular.ttf`, // Correct path to the font file in the public directory
});
Font.register({
  family: "Roboto",
  src: `${window.location.origin}/fonts/Roboto-Regular.ttf`, // Correct path to the font file in the public directory
});

const styles = StyleSheet.create({
  page: {
    width: 6.5 * 72, // 6 inches + 0.5 inches for bleed (in points)
    height: 9.5 * 72, // 9 inches + 0.5 inches for bleed (in points)
    padding: 18, // 1/4 inch bleed
    backgroundColor: "yellow",
  },
  cropMarkTopLeft: {
    position: "absolute",
    top: 0,
    left: 18,
    width: 18,
    height: 1,
    backgroundColor: "black",
    zIndex: -99,
  },
  cropMarkTopRight: {
    position: "absolute",
    top: 18,
    right: 18,
    width: 18,
    height: 1,
    backgroundColor: "black",
    zIndex: -99,
  },
  cropMarkBottomRight: {
    position: "absolute",
    bottom: 18,
    right: 18,
    width: 18,
    height: 1,
    backgroundColor: "black",
    zIndex: -99,
  },
  cropMarkBottomLeft: {
    position: "absolute",
    bottom: 18,
    left: 18,
    width: 18,
    height: 1,
    backgroundColor: "black",
    zIndex: -99,
  },
  page2: {
    width: 6.5 * 72, // 6 inches + 0.5 inches for bleed (in points)
    height: 9.5 * 72, // 9 inches + 0.5 inches for bleed (in points)
    backgroundColor: "white",
    padding: "25pt", // Increase padding for better spacing
  },
  content: {
    margin: 18, // Inner margin for content
    textAlign: "center",
    fontSize: 12,
  },
  header: {
    marginTop: "10pt",
    marginBottom: "15pt",
    textAlign: "center",
    fontSize: "30pt",
    fontFamily: "Lobster",
    color: "#003366",
  },
  subHeader: {
    marginBottom: "15pt",
    textAlign: "center",
    fontSize: "16pt",
    fontFamily: "Roboto",
    color: "#228B22",
  },
  imageContainer: {
    width: "3.85in",
    height: "2.85in",
    alignSelf: "center",
    marginBottom: "20pt",
    border: "1px solid #ccc",
    borderRadius: "10pt",
    backgroundColor: "#f0f0f0",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  image: {
    width: "3.75in",
    height: "2.75in",
    borderRadius: "10pt",
  },

  textTitle: {
    textAlign: "center",
    fontSize: "22pt",
    fontFamily: "Lobster",
    color: "#36454F",
    marginBottom: "20pt",
  },
  textSubTitle: {
    textAlign: "center",
    fontSize: "18pt",
    fontFamily: "Roboto",
    fontWeight: "bold",
    color: "#36454F",
    marginBottom: "15pt",
  },
  textContainer: {
    textAlign: "center",
    fontSize: "14pt",
    fontFamily: "Roboto",
    color: "#555",
    marginBottom: "15pt",
  },
  lineText: {
    marginBottom: "10pt",
    color: "#555555",
  },
  lineAnswers: {
    color: "#4169E1",
    fontWeight: "bold",
    marginBottom: "10pt",
  },
  callToAction: {
    marginTop: "10pt",
    textAlign: "center",
    fontSize: "14pt",
    fontFamily: "Roboto",
    fontWeight: "900",
    color: "#800000",
    marginLeft: "50pt",
    marginRight: "50pt",
  },
  errorText: {
    color: "red",
    textAlign: "center",
    fontSize: "10pt",
    fontFamily: "Roboto",
  },
  rotatedContainer: {
    transform: "rotate(90deg)",
    transformOrigin: "center",
    width: 432,
    height: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rotatedContent: {
    width: 648,
    height: 432,
    position: "relative",
  },
  colorBox: {
    position: "absolute",
    top: "-10pt", // Align with the top of the QR code container
    left: "-30pt", // Align with the left edge of the info box
    width: "600pt", // Same width as the QR code note and info box
    height: "300pt", // Enough height to cover the text and QR code area
    backgroundColor: "lightblue", // Light gray background, choose any color you like
    zIndex: -91, // Ensure it's behind the text content
  },
  qrCodeContainer: {
    position: "absolute",
    top: "130pt",
    left: "70pt",
    width: "250pt",
    textAlign: "center",
  },
  qrCodeNote: {
    position: "absolute",
    fontSize: "8pt",
    top: "261pt",
    left: "18pt",
    width: "250pt",
    textAlign: "center",
  },
  qrCode: {
    width: "2in",
    height: "2in",
  },
  infoBox: {
    width: "250pt",
    position: "absolute",
    top: "54pt",
    left: "32pt",
    textAlign: "left",
    fontSize: "16pt",
    fontFamily: "Roboto",
    color: "#555",
  },
  contestDisclaimer: {
    position: "absolute",
    width: "250pt",
    fontSize: "9pt",
    top: "285pt",
    left: "32pt",
    textAlign: "left",
    color: "#555",
  },
  disclaimer: {
    position: "absolute",
    bottom: "0pt",
    left: "75%",
    transform: "translateX(-100%)",
    textAlign: "left",
    fontSize: "8pt",
    fontFamily: "Roboto",
    color: "#555",
  },
  returnAddressContainer: {
    position: "absolute",
    top: "50pt",
    right: "34pt",
    textAlign: "right",
    fontSize: "10pt",
    fontFamily: "Roboto",
    color: "#555",
  },
  contactInfoContainer: {
    position: "absolute",
    bottom: "0pt",
    left: "30pt",
    textAlign: "left",
    fontSize: "10pt",
    fontFamily: "Roboto",
    color: "#555",
  },
  postage: {
    width: "72.5px",
    height: "101px",
    position: "absolute",
    left: "540pt",
    top: "180pt",
  },

  RLPImage: {
    position: "absolute",
    top: "100",
    left: "340pt",
    width: "75px",
    height: "37px",
  },

  DMRTImage: {
    position: "absolute",
    top: "50",
    left: "300pt",
    width: "150px",
    height: "40px",
  },
  alignCodeF: {
    fontSize: "7pt",
    left: "20pt",
    textAlign: "left",
    color: "#2F4F4F",
  },
  alignCodeB: {
    fontSize: "7pt",
    textAlign: "right",
    color: "#2F4F4F",
  },
  addressInfoContainer: {
    position: "absolute",
    bottom: "64pt",
    right: "130pt",
    textAlign: "right",
    fontSize: "16pt",
    fontFamily: "Roboto",
    color: "#555",
  },
  logoContainer: {
    position: "absolute",
    bottom: "100pt",
    right: "24pt",
    textAlign: "right",
  },
  logo: {
    width: "1.5in",
    height: "auto",
    margin: "5pt",
  },
});

const initializeData = async (listings) => {
  return Promise.all(
    listings.map(async (listing) => {
      const { city, province, postalCode } = await fetchLocationDetails(
        listing
      );
      const { yearSold, monthSold } = getSaleDateDetails(listing.DateSold);
      const shortIdentifier = listing.shortIdentifier?.toUpperCase() || "";
      const qrUrl = await generateQRCode(shortIdentifier);
      return {
        ...listing,
        city,
        province,
        postalCode,
        yearSold,
        monthSold,
        shortIdentifier,
        qrUrl,
        qrError: !qrUrl,
      };
    })
  );
};

const fetchLocationDetails = async (listing) => {
  if (listing.PostalCode) {
    // console.log(`Going to lookkup city from postal code ${listing.PostalCode}`);
    return getCityFromPostalCode(listing.PostalCode);
  } else if (listing.Address && listing.Area) {
    const addressString = `${listing.Address}, ${listing.Area}, BC, Canada`;
    const postalCode = await getPostalCodeFromAddress(addressString);
    return {
      ...(await getCityFromPostalCode(postalCode)),
      postalCode,
    };
  }
  return {};
};

const getSaleDateDetails = (dateSold) => {
  if (!dateSold) return {};
  const date = new Date(dateSold);
  return {
    yearSold: date.getFullYear(),
    monthSold: date.getMonth() + 1, // getMonth() is zero-based
  };
};

const generateQRCode = async (shortIdentifier) => {
  try {
    const protocol = process.env.NODE_ENV === "development" ? "https" : "https";

    let qrUrlBase = `${protocol}://pc.RealtyOS.co/lp/`; // Modify as needed
    // qrUrlBase = `${protocol}://localhost:3003/lp/`;

    const qrString = `${qrUrlBase}${shortIdentifier}`;
    return await QRCode.toDataURL(qrString);
  } catch (error) {
    console.error("Error generating QR code:", error);
    return null;
  }
};

// Utility function to convert Buffer to Base64
const bufferToBase64 = (buf) => {
  var binstr = Array.prototype.map
    .call(buf, function (ch) {
      return String.fromCharCode(ch);
    })
    .join("");
  return btoa(binstr);
};

const PostcardPDF = ({ listings }) => {
  // const [qrUrl, setQrUrl] = useState("");
  // const [qrError, setQrError] = useState(false);
  // const [city, setCity] = useState(listings[0]?.City || "");
  // const [province, setProvince] = useState(listings[0]?.Province || "");
  // const [address, setAddress] = useState(listings[0]?.Address || "");
  // const [postalCode, setPostalCode] = useState(listings[0]?.PostalCode || "");
  // const [error, setError] = useState(null);
  // const [yearSold, setYearSold] = useState();
  // const [monthSold, setMonthSold] = useState();
  const [shortIdentifier] = useState(
    listings[0].shortIdentifier?.toUpperCase()
  );
  let qrUrlBase = `https://pc.RealtyOS.co/lp/`;
  const postageUrl = "/img/stamp-cornhusk.png";
  const DMRTUrl = "/img/DMRT.png";
  const RLPUrl = "/img/RLP.png";

  const truncatePrice = (price) => Math.floor(price);
  const imageSrc = (image) =>
    `data:image/jpeg;base64,${bufferToBase64(image.data)}`;

  const [initializedListings, setInitializedListings] = useState([]);

  useState(() => {
    initializeData(listings).then(setInitializedListings);
  }, []); // Mimics componentDidMount to load data once

  return (
    <Document>
      {initializedListings.map((listing, index) => (
        <React.Fragment key={index}>
          {/* <Page size={{ width: 432, height: 648 }} style={styles.page}> */}
          <Page
            size={{ width: styles.page.width, height: styles.page.height }}
            style={styles.page}
          >
            {/* <View style={styles.cropMarkTopLeft} /> */}
            <View
              style={[
                styles.cropMarkTopLeft,
                { top: 18, right: 0, left: 0, width: 14, height: 1 },
              ]}
            />
            <View style={[styles.cropMarkTopLeft, { width: 1, height: 15 }]} />

            <View
              style={[
                styles.cropMarkTopRight,
                { top: 18, right: 0, width: 14, height: 1 },
              ]}
            />
            <View
              style={[
                styles.cropMarkTopRight,
                { top: 0, width: 1, height: 15 },
              ]}
            />

            <View
              style={[
                styles.cropMarkBottomRight,
                { bottom: 18, right: 0, width: 14, height: 1 },
              ]}
            />
            <View
              style={[
                styles.cropMarkBottomRight,
                { width: 1, height: 15, bottom: 0 },
              ]}
            />
            <View
              style={[
                styles.cropMarkBottomLeft,
                { bottom: 18, left: 0, width: 14, height: 1 },
              ]}
            />
            <View
              style={[
                styles.cropMarkBottomLeft,
                { width: 1, height: 15, bottom: 0 },
              ]}
            />

            <View style={styles.header}>
              <Text>Does This Look Familiar?</Text>
            </View>
            <View>
              <Text style={styles.subHeader}>
                Take a POP Quiz for a chance to Win!
              </Text>
            </View>
            {listing.image ? (
              <View style={styles.imageContainer}>
                <Image
                  style={styles.image}
                  src={imageSrc(listing.image.data)}
                />
              </View>
            ) : (
              <View style={styles.imageContainer}>
                <Image
                  style={styles.image}
                  src={`${window.location.origin}/img/placeholder-image.png`}
                />
              </View>
            )}
            <View style={styles.textTitle}>
              <Text>POP Quiz Time!</Text>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.textSubTitle}>Do you remember...</Text>
              <Text style={styles.lineText}>
                What year you bought your home?
              </Text>
              <Text style={styles.lineAnswers}>
                {listing.yearSold - 1}, {listing.yearSold},{" "}
                {listing.yearSold + 1}
              </Text>
              <Text style={styles.lineText}>
                What Month you bought your home?
              </Text>
              <Text style={styles.lineAnswers}>
                {getMonthName(listing.monthSold - 1)},{" "}
                {getMonthName(listing.monthSold)},{" "}
                {getMonthName(listing.monthSold + 1)}
              </Text>
              <Text style={styles.lineText}>
                How much you paid for your home?
              </Text>
              <Text style={styles.lineAnswers}>
                ${truncatePrice((listing.PriceSold * 0.98) / 1000)}K, $
                {listing.PriceSold / 1000}K, $
                {truncatePrice((listing.PriceSold * 1.02) / 1000)}
                K, ${truncatePrice((listing.PriceSold * 1.05) / 1000)}K
              </Text>
            </View>
            <View style={styles.callToAction}>
              <Text>
                Scan the QR code on the back to see if you are right and enter
                to win a gift card to Tim Hortons!
              </Text>
            </View>
            <View>
              <Text style={styles.alignCodeF}> {listing.shortIdentifier}</Text>
            </View>
          </Page>
          <Page
            size={{ width: styles.page.width, height: styles.page.height }}
            style={styles.page2}
          >
            <View style={styles.colorBox} />
            <View
              style={[
                styles.cropMarkTopLeft,
                { top: 18, right: 0, left: 0, width: 14, height: 1 },
              ]}
            />
            <View style={[styles.cropMarkTopLeft, { width: 1, height: 15 }]} />

            <View
              style={[
                styles.cropMarkTopRight,
                { top: 18, right: 0, width: 14, height: 1 },
              ]}
            />
            <View
              style={[
                styles.cropMarkTopRight,
                { top: 0, width: 1, height: 15 },
              ]}
            />

            <View
              style={[
                styles.cropMarkBottomRight,
                { bottom: 18, right: 0, width: 14, height: 1 },
              ]}
            />
            <View
              style={[
                styles.cropMarkBottomRight,
                { width: 1, height: 15, bottom: 0 },
              ]}
            />
            <View
              style={[
                styles.cropMarkBottomLeft,
                { bottom: 18, left: 0, width: 14, height: 1 },
              ]}
            />
            <View
              style={[
                styles.cropMarkBottomLeft,
                { width: 1, height: 15, bottom: 0 },
              ]}
            />
            <View style={styles.rotatedContainer}>
              <View style={styles.rotatedContent}>
                <View style={styles.qrCodeContainer}>
                  {listing.campaigns[0].SID ? (
                    <Image
                      style={styles.qrCode}
                      src={generateQRCode(listing.campaigns[0].SID)}
                    />
                  ) : (
                    <Text style={styles.errorText}>
                      Generating QR Code failed
                    </Text>
                  )}
                </View>
                <View>
                  <Image style={styles.postage} src={postageUrl} />
                </View>
                <View style={styles.qrCodeNote}>
                  <Text>
                    {qrUrlBase}
                    {listing.shortIdentifier}
                  </Text>
                </View>
                <View style={styles.infoBox}>
                  <Text>
                    Scan this QR Code to answer the questions. Get them right
                    and enter to win a $50 Tim Hortons gift card!
                  </Text>
                </View>
                <View>
                  <Image style={styles.DMRTImage} src={DMRTUrl} />
                </View>
                <View>
                  <Image style={styles.RLPImage} src={RLPUrl} />
                </View>
                <View style={styles.returnAddressContainer}>
                  <Text>Dan Morris Real Estate Team</Text>
                  <Text>Royal LePage Nanaimo Realty</Text>
                  <Text>4200 Island Highway North</Text>
                  <Text>Nanaimo, BC, V9T 1W6</Text>
                </View>
                <View style={styles.contactInfoContainer}>
                  <Text>Dan Morris Real Estate Team</Text>
                  <Text>Royal LePage Nanaimo Realty</Text>
                  <Text>Office: 250-760-2231</Text>
                  <Text>info@danmorris.ca</Text>
                  <Text>www.danmorris.ca</Text>
                  <Text style={styles.alignCodeB}>
                    {listing.shortIdentifier}
                  </Text>
                </View>
                <View style={styles.addressInfoContainer}>
                  <Text>Home Owner</Text>
                  <Text>{listing.Address}</Text>
                  <Text>
                    {listing.city}, {listing.province}
                  </Text>
                  <Text>{listing.PostalCode}</Text>
                </View>
                <View style={styles.logoContainer}>
                  <Image
                    style={styles.logo}
                    src={`${window.location.origin}/path-to-dan-morris-logo.png`}
                  />
                  <Image
                    style={styles.logo}
                    src={`${window.location.origin}/path-to-royal-lepage-logo.png`}
                  />
                </View>
                <View style={styles.disclaimer}>
                  <Text>
                    Not intended to solicit homes already listed for sale.
                  </Text>
                </View>
                <View style={styles.contestDisclaimer}>
                  <Text style={styles.lineText}>
                    One gift card awarded each month for the remainder of 2024.
                    One entry per address. No purchase necessary.
                  </Text>
                  <Text style={styles.lineText}>
                    Visit: www.danmorris.ca/TimHortonsContest for rules and
                    conditions.
                  </Text>
                </View>
              </View>
            </View>
          </Page>
        </React.Fragment>
      ))}
    </Document>
  );
};

const PostcardViewer = ({ listings, campaignId, imageUrl }) => {
  const dispatch = useDispatch();

  return (
    <PDFViewer style={{ width: "100%", height: "90vh" }}>
      <PostcardPDF
        dispatch={dispatch}
        listings={listings}
        campaignId={campaignId}
        imageUrl={imageUrl}
      />
    </PDFViewer>
  );
};

export default PostcardViewer;
