import React from "react";
import { Link } from "react-router-dom";

const HomePage = () => {
  const MONGODB_URI = process.env.MONGODB_URI;

  return (
    <div className="home-container">
      <h1>Welcome to the Real Estate Application</h1>
      <p>Your one-stop solution for managing property listings and analyses.</p>
      <div>
        <h2>Explore Our Features</h2>
        <ul>
          <li>
            <Link to="/listings">View Property Listings</Link>
          </li>
          <li>
            <Link to="/analysis">Property Analysis</Link>
          </li>
          <li>
            <Link to="/mls-import">MLS Import</Link>
          </li>
        </ul>
      </div>

    </div>
  );
};

export default HomePage;
