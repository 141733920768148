import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAnalysisResults } from "../actions/analysisActions";
import { useAuth } from "../contexts/AuthContext";

import {
  fetchCampaigns,
  createCampaign,
  associateListingsToCampaign,
} from "../actions/campaignActions";

import {
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  OutlinedInput,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { propertySubTypes, areas, subAreas } from "../data/lists";
import {
  getAnalysisResults,
  getAnalysisLoading,
  getCampaignsData,
} from "../selectors";
import CampaignPage from "./CampaignPage";

const AnalysisPage = () => {
  const dispatch = useDispatch();
  const analysisResults = useSelector(getAnalysisResults);
  const analysisLoading = useSelector(getAnalysisLoading);
  const campaigns = useSelector(getCampaignsData);
  // const campaignsLoading = useSelector(getCampaignsLoading);
  // const campaignsError = useSelector(getCampaignsError);
  const [limit, setLimit] = useState(0); // Default limit
  const { user } = useAuth();

  useEffect(() => {
    dispatch(fetchCampaigns());
  }, [dispatch]);

  const [years, setYears] = useState(7);
  const [yearsMax, setYearsMax] = useState(12);
  const [areaCode, setAreaCode] = useState("Na");
  const [subArea, setSubArea] = useState("");
  const [propertySubType, setPropertySubType] = useState(
    "Single Family Detached"
  );
  const [street, setStreet] = useState(""); // New state for street
  const [filteredSubAreas, setFilteredSubAreas] = useState([]);
  const [yearsInactive, setYearsInactive] = useState(1); // Default to no selection
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedListings, setSelectedListings] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [showCampaignPanel, setShowCampaignPanel] = useState(false);

  useEffect(() => {
    if (areaCode) {
      const selectedArea = areas.find((a) => a.code === areaCode);
      if (selectedArea) {
        const filtered = subAreas.filter((sa) =>
          sa.name.startsWith(selectedArea.code)
        );
        setFilteredSubAreas(filtered);
      }
    } else {
      setFilteredSubAreas([]);
    }
  }, [areaCode]);

  const handleSearch = () => {
    const selectedArea = areas.find((a) => a.code === areaCode);
    const areaName = selectedArea ? selectedArea.name : "";

    const selectedSubArea = subAreas.find((sa) => sa.name === subArea);
    const cityName = selectedSubArea ? selectedSubArea.city : "";

    const searchParams = {
      years,
      yearsMax,
      area: areaName,
      subArea,
      city: cityName, // Include the city in the search parameters
      propertySubType,
      street,
      statuses: selectedStatuses, // Include the selected statuses
      limit,
    };

    if (yearsInactive) {
      searchParams.yearsInactive = yearsInactive;
    }
    dispatch(fetchAnalysisResults(searchParams));
  };

  const calculateYearsSinceSold = (dateSold) => {
    const currentDate = new Date();
    const soldDate = new Date(dateSold);
    return currentDate.getFullYear() - soldDate.getFullYear();
  };

  const isAgentInactive = (checkDateInput) => {
    const currentDate = new Date();
    const checkDate = new Date(checkDateInput);

    return currentDate.getFullYear() - checkDate.getFullYear() > yearsInactive;
  };

  const extractStreetParts = (address) => {
    const parts = address.split(" ");
    const streetNumber = parseInt(parts[0], 10);
    const streetName = parts.slice(1).join(" ");
    return { streetNumber, streetName };
  };

  const sortedResults = analysisResults.sort((a, b) => {
    const aParts = extractStreetParts(a.Address);
    const bParts = extractStreetParts(b.Address);

    if (aParts.streetName < bParts.streetName) return -1;
    if (aParts.streetName > bParts.streetName) return 1;
    if (aParts.streetNumber < bParts.streetNumber) return -1;
    if (aParts.streetNumber > bParts.streetNumber) return 1;

    return 0;
  });

  const formatDate = (date) => {
    const dateObj = new Date(date); // Attempt to convert the input into a Date object

    // Check if the date is valid by ensuring it's not NaN
    if (!isNaN(dateObj.getTime())) {
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "America/Los_Angeles", // Specify the time zone to ensure consistency
      }).format(dateObj);
    } else {
      return "Invalid Date"; // Provide feedback or handle invalid date appropriately
    }
  };

  const handleSelectChange = (id) => {
    const newSelectedListings = selectedListings.includes(id)
      ? selectedListings.filter((listingId) => listingId !== id)
      : [...selectedListings, id];
    setSelectedListings(newSelectedListings);
  };

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      // When checking the select all, gather all listing IDs
      const allListings = sortedResults.map(
        (result) => result.mostRecentListingId
      );
      setSelectedListings(allListings);
    } else {
      // When unchecking the select all, clear the selections
      setSelectedListings([]);
    }
  };

  const handleCampaignSubmit = async () => {
    if (selectedCampaign && selectedListings.length > 0) {
      if (!user) {
        alert("User is not authenticated");
        return;
      }
      const {
        name,
        campaignDate,
        completedDate,
        status,
        vendor,
        printingCost,
        postageCost,
        totalCost,
        quantityMailed,
        responseRate,
        responsesReceived,
        artworkFiles,
        materialsDescription,
        objectives,
        deliveryStartDate,
        deliveryEndDate,
        feedback,
        notes,
        mailingList,
      } = selectedCampaign;

      // Assuming `currentUser` is accessible and contains the user's ID
      const campaignDetails = {
        name: name,
        createdBy: user,
        campaignDate: campaignDate,
        completedDate: completedDate,
        status: status,
        vendor: vendor,
        printingCost: printingCost,
        postageCost: postageCost,
        totalCost: totalCost,
        quantityMailed: quantityMailed,
        responseRate: responseRate,
        responsesReceived: responsesReceived,
        artworkFiles: artworkFiles,
        materialsDescription: materialsDescription,
        objectives: objectives,
        deliveryStartDate: deliveryStartDate,
        deliveryEndDate: deliveryEndDate,
        feedback: feedback,
        notes: notes,
        // Including mailingList if you have a reference ID available
        mailingList: mailingList,
      };

      if (!selectedCampaign._id) {
        // Create a new campaign if no ID exists
        try {
          const action = await dispatch(createCampaign(campaignDetails));

          if (action.type === "CREATE_CAMPAIGN_SUCCESS") {
            const newCampaignId = action.payload._id;
            // console.log(action.payload);
            setSelectedCampaign((prev) => ({ ...prev, _id: newCampaignId }));
            // Associate listings to the newly created campaign
            await dispatch(
              associateListingsToCampaign(newCampaignId, selectedListings)
            );
          } else {
            throw new Error("Failed to create campaign");
          }
        } catch (error) {
          console.error("Error in creating campaign:", error);
        }
      } else {
        // Associate listings to an existing campaign
        try {
          await dispatch(
            associateListingsToCampaign(selectedCampaign._id, selectedListings)
          );
        } catch (error) {
          console.error("Error in associating listings:", error);
        }
      }
      setShowCampaignPanel(true);
    } else {
      alert("Please select a campaign and at least one listing.");
    }
  };

  // const handleShowCampaignPanel = () => {
  //   setShowCampaignPanel(true);
  // };
  const closeCampaignPanel = () => {
    setShowCampaignPanel(false);
  };

  const handleInputChange = (event, value) => {
    setSelectedCampaign(null);
    setSelectedCampaign({ _id: null, name: value });
    // setShowCampaignPanel(false);
  };

  const handleCampaignChange = (event, newValue) => {
    if (newValue) {
      setSelectedCampaign(newValue);
      setShowCampaignPanel(true);
    } else {
      setSelectedCampaign(null);
      // setShowCampaignPanel(false);
    }
  };
  const handleCampaignDelete = () => {
    setSelectedCampaign(null);
    setShowCampaignPanel(false);
  };

  return (
    <Box className="analysis-container">
      <Typography variant="h4">Analysis Page</Typography>
      <Box className="analysis-form" component={Paper} padding={2} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Number of Years (Min)"
              type="number"
              value={years}
              onChange={(e) => setYears(e.target.value)}
              fullWidth
              margin="dense"
              sx={{ p: 0, m: 0 }}
            />
          </Grid>{" "}
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Number of Years (Max)"
              type="numberMax"
              value={yearsMax}
              onChange={(e) => setYearsMax(e.target.value)}
              fullWidth
              margin="dense"
              sx={{ p: 0, m: 0 }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth margin="dense" sx={{ p: 0, m: 0 }}>
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                labelId="status-select-label"
                multiple
                value={selectedStatuses}
                onChange={(event) => {
                  const value = event.target.value;
                  setSelectedStatuses(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                input={<OutlinedInput label="Status" />}
                renderValue={(selected) =>
                  selected
                    .map((status) => {
                      switch (status) {
                        case "ACT":
                          return "Active";
                        case "SLD":
                          return "Sold";
                        case "EXP":
                          return "Expired";
                        case "CAN":
                          return "Cancelled";
                        case "WTH":
                          return "Withdrawn";
                        default:
                          return status;
                      }
                    })
                    .join(", ")
                }
              >
                {["ACT", "SLD", "EXP", "CAN", "WTH"].map((status) => (
                  <MenuItem key={status} value={status}>
                    {status === "ACT"
                      ? "Active"
                      : status === "SLD"
                      ? "Sold"
                      : status === "EXP"
                      ? "Expired"
                      : status === "CAN"
                      ? "Cancelled"
                      : "Withdrawn"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth margin="dense" sx={{ p: 0, m: 0 }}>
              <InputLabel>Years Inactive</InputLabel>
              <Select
                value={yearsInactive}
                onChange={(e) => setYearsInactive(e.target.value)}
                label="Years Inactive"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {[1, 2, 3, 4, 5].map((year) => (
                  <MenuItem key={year} value={year}>
                    {year} {year === 1 ? "Year" : "Years"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth margin="dense" sx={{ p: 0, m: 0 }}>
              <InputLabel>Area</InputLabel>
              <Select
                value={areaCode}
                onChange={(e) => setAreaCode(e.target.value)}
                label="Area"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {areas.map((type) => (
                  <MenuItem key={type.code} value={type.code}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth margin="dense" sx={{ p: 0, m: 0 }}>
              <InputLabel>Sub Area</InputLabel>
              <Select
                value={subArea}
                onChange={(e) => setSubArea(e.target.value)}
                label="Sub Area"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {filteredSubAreas?.map((type) => (
                  <MenuItem key={type.name} value={type.name}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth margin="dense" sx={{ p: 0, m: 0 }}>
              <InputLabel>Property Sub Type</InputLabel>
              <Select
                value={propertySubType}
                onChange={(e) => setPropertySubType(e.target.value)}
                label="Property Sub Type"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {propertySubTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Limit"
              type="number"
              value={limit}
              onChange={(e) => setLimit(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Street"
              type="text"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              fullWidth
              margin="dense"
              sx={{ p: 0, m: 0 }}
            />
            <Checkbox
              checked={
                selectedListings.length === sortedResults.length &&
                sortedResults.length > 0
              }
              indeterminate={
                selectedListings.length > 0 &&
                selectedListings.length < sortedResults.length
              }
              onChange={handleSelectAllChange}
            />
            <Autocomplete
              freeSolo
              value={selectedCampaign}
              onInputChange={handleInputChange}
              onChange={handleCampaignChange}
              onFocus={() => setShowCampaignPanel(true)} // Set the campaign panel to show when the input is focused
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={campaigns}
              getOptionLabel={(option) => {
                // Displaying the label for each option
                if (typeof option === "string") {
                  return option; // This might not be needed unless your options are strings
                }
                return option.name; // Assuming your options are objects with a 'name' property
              }}
              renderOption={(props, option) => {
                const { key, ...otherProps } = props;
                return (
                  <li key={key} {...otherProps}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose or create a campaign"
                  variant="outlined"
                />
              )}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleCampaignSubmit}
            >
              Submit to Campaign
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
      {analysisLoading ? (
        <CircularProgress />
      ) : (
        <Box className="analysis-container">
          {sortedResults && (
            <Box className="analysis-results">
              <Typography variant="h5" gutterBottom>
                Total Matches: {sortedResults.length}
              </Typography>
              {sortedResults.map((result) => (
                <Box
                  key={result.mostRecentListingId}
                  className="result-item"
                  mb={3}
                  component={Paper}
                  padding={2}
                  sx={{
                    backgroundColor:
                      result.mostRecentStatus === "ACT"
                        ? "#e8f5e9"
                        : result.mostRecentStatus === "SLD"
                        ? "#ffeaee"
                        : "#efffff",
                  }}
                >
                  <Checkbox
                    checked={selectedListings.includes(
                      result.mostRecentListingId
                    )}
                    onChange={() =>
                      handleSelectChange(result.mostRecentListingId)
                    }
                  />
                  <Typography variant="h6">
                    {result.Address} - {result.SubArea}
                  </Typography>
                  <Typography
                    style={{
                      fontWeight:
                        result.mostRecentStatus === "SLD" ? "bold" : "normal",
                      color:
                        result.mostRecentStatus === "SLD" ? "red" : "inherit",
                    }}
                  >
                    <span style={{ fontWeight: "bolder" }}>
                      Most Recent Status: {result.mostRecentStatus}
                    </span>
                  </Typography>

                  <Typography>
                    <span style={{ fontWeight: "bolder" }}>
                      Most Recent Date:
                    </span>{" "}
                    {formatDate(result.mostRecentDate)}
                  </Typography>
                  <Typography>
                    <span style={{ fontWeight: "bolder" }}>
                      Years Since Last Listing:{" "}
                    </span>
                    {calculateYearsSinceSold(result.mostRecentDate)}
                  </Typography>

                  <Typography>
                    <span style={{ fontWeight: "bolder" }}>Last Agent:</span>{" "}
                    {result.mostRecentAgent}{" "}
                    <span
                      style={{
                        color: isAgentInactive(
                          result.mostRecentAgentTransaction
                        )
                          ? "red"
                          : "green",
                      }}
                    >
                      ({formatDate(result.mostRecentAgentTransaction)})
                    </span>
                  </Typography>

                  {result.mostRecentCoAgent && (
                    <Typography>
                      <span style={{ fontWeight: "bolder" }}>
                        Most Recent CoAgent:
                      </span>{" "}
                      {result.mostRecentCoAgent}{" "}
                      <span
                        style={{
                          color: isAgentInactive(
                            result.mostRecentCoAgentTransaction
                          )
                            ? "red"
                            : "green",
                        }}
                      >
                        ({formatDate(result.mostRecentCoAgentTransaction)})
                      </span>
                    </Typography>
                  )}
                  {result.mostRecentCoAgent2 && (
                    <Typography>
                      <span style={{ fontWeight: "bolder" }}>
                        Most Recent Agent 2:
                      </span>{" "}
                      {result.mostRecentCoAgent2}{" "}
                      <span
                        style={{
                          color: isAgentInactive(
                            result.mostRecentCoAgent2Transaction
                          )
                            ? "red"
                            : "green",
                        }}
                      >
                        ({formatDate(result.mostRecentCoAgent2Transaction)})
                      </span>
                    </Typography>
                  )}

                  <Typography>
                    <span style={{ fontWeight: "bolder" }}>
                      Transaction Count:
                    </span>{" "}
                    {result.count}
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="table-header-cell">
                            Price List
                          </TableCell>
                          <TableCell className="table-header-cell">
                            Status
                          </TableCell>
                          <TableCell className="table-header-cell">
                            Listing Realtor
                          </TableCell>
                          <TableCell className="table-header-cell">
                            Buying Realtor
                          </TableCell>
                          <TableCell className="table-header-cell">
                            Date
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {result.listings.map((listing) => (
                          <TableRow key={listing._id}>
                            <TableCell>${listing.PriceList}</TableCell>
                            <TableCell>{listing.Status}</TableCell>
                            <TableCell>{listing.Licensee}</TableCell>
                            <TableCell>{listing.Buyer}</TableCell>
                            <TableCell>
                              {" "}
                              {listing.Status === "SLD"
                                ? formatDate(listing.DateSold)
                                : formatDate(listing.DateListed)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
      {showCampaignPanel && selectedCampaign && (
        <CampaignPage
          campaignId={selectedCampaign._id}
          onClose={closeCampaignPanel}
          onDelete={handleCampaignDelete}
        />
      )}
    </Box>
  );
};

export default AnalysisPage;
