import axios from "axios";

// Action types
export const UPLOAD_LISTING_IMAGE_SUCCESS = "UPLOAD_LISTING_IMAGE_SUCCESS";
export const UPLOAD_LISTING_IMAGE_REQUEST = "UPLOAD_LISTING_IMAGE_REQUEST";
export const UPLOAD_LISTING_IMAGE_FAILURE = "UPLOAD_LISTING_IMAGE_FAILURE";
export const FETCH_LISTINGS_REQUEST = "FETCH_LISTINGS_REQUEST";
export const FETCH_LISTINGS_SUCCESS = "FETCH_LISTINGS_SUCCESS";
export const FETCH_LISTINGS_FAILURE = "FETCH_LISTINGS_FAILURE";
export const FETCH_LISTING_REQUEST = "FETCH_LISTING_REQUEST";
export const FETCH_LISTING_SUCCESS = "FETCH_LISTING_SUCCESS";
export const FETCH_LISTING_FAILURE = "FETCH_LISTING_FAILURE";
export const UPDATE_LISTING_REQUEST = "UPDATE_LISTING_REQUEST";
export const UPDATE_LISTING_SUCCESS = "UPDATE_LISTING_SUCCESS";
export const UPDATE_LISTING_FAILURE = "UPDATE_LISTING_FAILURE";
export const CONTEST_ENTRY_SUCCESS = "CONTEST_ENTRY_SUCCESS";
export const CONTEST_ENTRY_FAIL = "CONTEST_ENTRY_FAIL";

// Action creators for image upload
export const uploadListingImageSuccess = (listingId, image) => ({
  type: UPLOAD_LISTING_IMAGE_SUCCESS,
  payload: { listingId, image },
});

export const uploadListingImage =
  (campaignId, listingId, formData) => async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_LISTING_IMAGE_REQUEST });
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/campaigns/${campaignId}/listings/${listingId}/upload`,
        formData
      );
      const image = response.data.listing.image;
      dispatch(uploadListingImageSuccess(listingId, image));
      return response;
    } catch (error) {
      dispatch({
        type: UPLOAD_LISTING_IMAGE_FAILURE,
        payload: error.message,
      });
    }
  };

// Action creators for listing updates
export const updateListing = (listingId, updatedData) => async (dispatch) => {
  dispatch({ type: UPDATE_LISTING_REQUEST });
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/listings/${listingId}`,
      updatedData
    );
    dispatch({
      type: UPDATE_LISTING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_LISTING_FAILURE,
      payload: error.message,
    });
  }
};

// Action creators for fetching listings
export const fetchListings = () => async (dispatch) => {
  dispatch({ type: FETCH_LISTINGS_REQUEST });
  try {
    const response = await axios.get(`${process.moment.API_URL}/listings`);
    dispatch({ type: FETCH_LISTINGS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_LISTINGS_FAILURE, payload: error.message });
  }
};

// Fetch a single listing by short ID
export const fetchListingByShortId = (shortId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_LISTING_REQUEST });
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/listings/${shortId.toLowerCase()}`
    );
    const data = await response.json();
    if (!response.ok) throw new Error(data.message || "Failed to fetch");
    dispatch({ type: FETCH_LISTING_SUCCESS, payload: data });
    return { payload: data };
  } catch (error) {
    dispatch({ type: FETCH_LISTING_FAILURE, error });
    return { error };
  }
};
