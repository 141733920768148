// src/reducers/index.js
import { combineReducers } from "redux";
import analysisReducer from "./analysisReducer";
import campaignsReducer from "./campaignsReducer";
import listingReducer from "./listingReducer";

const rootReducer = combineReducers({
  analysis: analysisReducer,
  campaigns: campaignsReducer,
  listing: listingReducer,
});

export default rootReducer;
