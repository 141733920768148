// Footer.js

import React from "react";
import { Box, Typography } from "@mui/material";

const Footer = () => (
  <Box
    sx={{
      border: "2px solid hsl(210deg 76% 47%)",
      borderRadius: "8px",
      p: 2,
      mt: 1,
      bgcolor: "white",
      textAlign: "center",
    }}
  >
    {/* Placeholder for logo on the left */}
    <img
      src="/img/DMRT.png"
      width="100px"
      alt="Left Logo"
      style={{ marginLeft: "10px" }}
    />
    <Typography variant="body2">
      The Dan Morris Real Estate Team, Royal LePage Nanaimo Realty, 4200 Island
      Hwy North, Nanaimo, V9T 1W6
    </Typography>
    <Box>
      {/* Placeholder for logo on the right */}
      <img
        src="/img/RLP.png"
        alt="Right Logo"
        width="100px"
        style={{ marginRight: "10px" }}
      />
    </Box>
  </Box>
);

export default Footer;
