import React, { Suspense } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import { Container } from "@mui/material";
import { AuthProvider } from "./contexts/AuthContext";
import PostalCodeLookup from "./components/PostalCodeLookup";

import LoginPage from "./components/login/LoginPage";
import PrivateRoute from "./components/PrivateRoute"; // Adjusted to reflect singular import
import AdminRoute from "./components/AdminRoute"; // Ensure AdminRoute is correctly imported
import NavBar from "./components/nav/NavBar";
import HomePage from "./pages/HomePage";
import LandingPage from "./pages/LandingPage";

import "./styles/App.css"; // Ensure the CSS path is correct
import "./styles/components/NavBar.css";
import "./styles/components/AnalysisPage.css";
import "./styles/components/LandingPage.css";
import "./styles/components/Campaign.css";
import "./styles/components/HomePage.css";
import "./styles/components/ListingItem.css";
import "./styles/components/MLSRecords.css"; // Make sure to create this CSS file

import AnalysisPage from "./pages/AnalysisPage";

const MLSImport = React.lazy(() => import("./components/MLSImport"));
const ListingsPage = React.lazy(() => import("./pages/ListingsPage"));
const RegisterPage = React.lazy(() =>
  import("./components/register/RegisterPage")
);

const isAuthenticated = () => {
  return !!localStorage.getItem("token");
};

function App() {
  const location = useLocation(); // Get the current location object
  const { pathname } = location; // Destructure to get the pathname directly

  return (
    <AuthProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <Container sx={{ m: 0, p: 0, width: "100%" }}>
          {!pathname.startsWith("/lp/") && isAuthenticated() && <NavBar />}
          <Routes>
            <Route path="/lp/:shortId" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/lookup" element={<PostalCodeLookup />} />
            <Route
              path="/"
              element={
                isAuthenticated() ? <HomePage /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/register"
              element={
                <AdminRoute>
                  <RegisterPage />
                </AdminRoute>
              }
            />
            <Route
              path="/mls-list"
              element={
                <PrivateRoute>
                  <ListingsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/listings"
              element={
                <PrivateRoute>
                  <ListingsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/analysis"
              element={
                <PrivateRoute>
                  <AnalysisPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/mls-import"
              element={
                <PrivateRoute>
                  <MLSImport />
                </PrivateRoute>
              }
            />
          </Routes>
        </Container>
      </Suspense>
    </AuthProvider>
  );
}

export default App;
