// src/utils/helpers.js

export const columns = [
  { key: "MLS", label: "MLS" },
  { key: "Status", label: "Status" },
  { key: "YearBuilt", label: "Year Built" },
  { key: "Address", label: "Address" },
  { key: "City", label: "City" },
  { key: "SubArea", label: "Sub Area" },
  { key: "PriceList", label: "Price List" },
  { key: "PriceSold", label: "Price Sold" },
  { key: "agents", label: "Agents" },
  { key: "PropertyType", label: "Property Type" },
  { key: "PropertySubType", label: "Property Sub Type" },

];

export const summaryOptions = ["None", "Sum", "Average", "Count", "Min", "Max"];

export const calculateSummary = (key, records, columnSummaries) => {
  const values = records.map((record) => Number(record[key]) || 0);
  const summaryType = columnSummaries[key];
  switch (summaryType) {
    case "Sum":
      return values.reduce((sum, value) => sum + value, 0);
    case "Average":
      return values.reduce((sum, value) => sum + value, 0) / values.length;
    case "Count":
      return values.length;
    case "Min":
      return Math.min(...values);
    case "Max":
      return Math.max(...values);
    default:
      return null;
  }
};

export const handleSort = (key, sortConfig, setSortConfig) => {
  let direction = "asc";
  if (sortConfig.key === key && sortConfig.direction === "asc") {
    direction = "desc";
  }
  setSortConfig({ key, direction });
};

  export const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  export const getMonthName = (monthNumber) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[monthNumber - 1]; // monthNumber is 1-12, so subtract 1 for 0-11 index
  };