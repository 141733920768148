import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Box,
  Typography,
  Paper,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  CircularProgress,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import PostcardViewer from "./PostcardPDF";

import {
  fetchCampaignById,
  updateCampaign,
  removeListingFromCampaign,
  removeAllListingsFromCampaign,
  removeListingsFromCampaign,
  deleteCampaign,
} from "../actions/campaignActions";
import ListingItem from "./ListingItem";
import CampaignEditForm from "./CampaignEditForm";

const CampaignPage = ({
  campaignId,
  onClose,
  onDelete,
  handleShowCampaignPanel,
}) => {
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaigns.campaign);
  const loading = useSelector((state) => state.campaigns.loading);
  const error = useSelector((state) => state.campaigns.error);
  const [showPostcard, setShowPostcard] = useState(false);
  const [updatedListingIds, setUpdatedListingIds] = useState([]);
  const [showOnlyNewListings, setShowOnlyNewListings] = useState(false); // State to manage filter

  const [editableCampaign, setEditableCampaign] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const campaignRef = useRef();

  useEffect(() => {
    if (campaignId) {
      dispatch(fetchCampaignById(campaignId));
    }
  }, [dispatch, campaignId]);

  useEffect(() => {
    if (campaign) {
      setEditableCampaign(campaign);
    }
  }, [campaign]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (campaignRef.current && !campaignRef.current.contains(event.target)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleChange = (name, value) => {
    setEditableCampaign((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateDifferentListingsCount = useCallback(
    (listingId) => {
      // Use a Set to manage unique listingIds
      setUpdatedListingIds((prevIds) => {
        const uniqueIds = new Set([...prevIds, listingId]);
        return Array.from(uniqueIds); // Convert back to array (if necessary)
      });
    },
    [setUpdatedListingIds]
  );

  const handleSave = () => {
    dispatch(updateCampaign(campaignId, editableCampaign));
    console.log(campaignId, editableCampaign);
    setIsEditing(false);
  };

  const handleDeleteListing = (listingId) => {
    dispatch(removeListingFromCampaign(campaignId, listingId));
  };

  const handleDeleteAllListings = () => {
    dispatch(removeAllListingsFromCampaign(campaignId));
  };

  const handleDeleteCampaign = () => {
    dispatch(deleteCampaign(campaignId)).then(() => {
      onDelete(); // Call onDelete prop to reset autocomplete
      onClose();
    });
  };

  const handleCreateAllPostcards = () => {
    setShowPostcard(true);
  };

  const closeModal = () => {
    setShowPostcard(false);
  };

  const handleImageUpload = (listingId, uploadedImage) => {
    setEditableCampaign((prev) => {
      const updatedListings = prev.listings.map((listing) =>
        listing._id === listingId
          ? { ...listing, image: uploadedImage }
          : listing
      );
      return { ...prev, listings: updatedListings };
    });
  };

  const toggleShowOnlyNewListings = () => {
    setShowOnlyNewListings((prev) => !prev);
  };

  const handleRemoveRelists = () => {
    dispatch(removeListingsFromCampaign(campaignId, updatedListingIds));

    setUpdatedListingIds([]); // Clear the updatedListingIds list
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error.message}</Typography>;
  }

  if (!editableCampaign) {
    return null;
  }

  // Filter listings based on showOnlyNewListings state
  const filteredListings = showOnlyNewListings
    ? editableCampaign.listings.filter((listing) =>
        updatedListingIds.includes(listing._id)
      )
    : editableCampaign.listings;

  return (
    <Box
      className="campaign-container"
      component={Paper}
      elevation={3}
      ref={campaignRef}
    >
      <Box className="campaign-header">
        <Typography variant="h6" className="campaign-title">
          Campaign Details
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      {isEditing ? (
        <>
          <CampaignEditForm
            campaign={editableCampaign}
            onChange={handleChange}
            onSave={handleSave}
            onDeleteAllListings={handleDeleteAllListings}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setIsEditing(false)}
          >
            Cancel
          </Button>
        </>
      ) : (
        <Box className="campaign-view">
          <Typography>
            <b>Name:</b> {editableCampaign.name}
          </Typography>
          <Typography>
            <b>Date:</b> {editableCampaign.campaignDate}
          </Typography>
          <Typography>
            <b>Status:</b> {editableCampaign.status}
          </Typography>
          <Typography>
            <b>Vendor:</b> {editableCampaign.vendor}
          </Typography>
          <Typography>
            <b>Cost:</b> ${editableCampaign.totalCost}
          </Typography>
          <Typography>
            <b>Quantity Mailed:</b> {editableCampaign.quantityMailed}
          </Typography>
          <Typography>
            <b>Response Rate:</b> {editableCampaign.responseRate}%
          </Typography>
          <Typography>
            <b>Responses Received:</b> {editableCampaign.responsesReceived}
          </Typography>
          <Typography>
            <b>Notes:</b> {editableCampaign.notes}
          </Typography>
          <Typography>
            <b>Objectives:</b> {editableCampaign.objectives}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsEditing(true)}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteAllListings}
            startIcon={<DeleteIcon />}
          >
            Delete All Listings
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteCampaign}
            startIcon={<DeleteIcon />}
          >
            Delete Campaign
          </Button>

          <Button
            variant="contained"
            color="success"
            startIcon={<SendIcon />}
            onClick={handleCreateAllPostcards}
          >
            Create All PDF Postcards
          </Button>
          <Button
            variant="contained"
            onClick={toggleShowOnlyNewListings}
            startIcon={showOnlyNewListings ? <CloseIcon /> : <SaveIcon />}
          >
            {showOnlyNewListings ? "Show All Listings" : "Show Relists"}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleRemoveRelists}
            startIcon={<DeleteIcon />}
          >
            Remove All Relists
          </Button>
          <Modal
            open={showPostcard}
            onClose={closeModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                outline: 0,
              }}
            >
              <Typography id="simple-modal-title" variant="h6" component="h2">
                View Postcard
              </Typography>
              <PostcardViewer
                listings={editableCampaign.listings}
                campaigId={editableCampaign}
                onClose={closeModal}
              />
              <Button onClick={closeModal} sx={{ mt: 2 }}>
                Close PDF
              </Button>
            </Box>
          </Modal>
        </Box>
      )}
      {filteredListings.length > 0 && (
        <Box mt={3}>
          <Typography variant="h6">Associated Listings</Typography>
          <TableContainer component={Paper}>
            <Table sx={{ m: 0, p: 0 }}>
              <TableHead>
                <TableRow sx={{ m: 0, p: 0 }}>
                  <TableCell colSpan={3} style={{ textAlign: "center" }}>
                    <span style={{ fontWeight: 700 }}>
                      # of Homes in Campaign:
                    </span>{" "}
                    {filteredListings.length}
                    <br />
                    Recent New Listings: {updatedListingIds.length}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredListings.map((listing, index) => (
                  <ListingItem
                    key={listing._id}
                    listing={listing}
                    campaignId={campaignId}
                    handleDeleteListing={handleDeleteListing}
                    onImageUpload={handleImageUpload}
                    index={index}
                    onUpdateDifferentListingsCount={() =>
                      handleUpdateDifferentListingsCount(listing._id)
                    }
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default CampaignPage;
