import {
  FETCH_CAMPAIGNS_REQUEST,
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_FAILURE,
  CREATE_CAMPAIGN_REQUEST,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,
  UPDATE_CAMPAIGN_REQUEST,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAILURE,
  DELETE_CAMPAIGN_REQUEST,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAILURE,
  FETCH_CAMPAIGN_REQUEST,
  FETCH_CAMPAIGN_SUCCESS,
  FETCH_CAMPAIGN_FAILURE,
  REMOVE_LISTING_FROM_CAMPAIGN_REQUEST,
  REMOVE_LISTING_FROM_CAMPAIGN_SUCCESS,
  REMOVE_LISTING_FROM_CAMPAIGN_FAILURE,
  REMOVE_ALL_LISTINGS_FROM_CAMPAIGN_REQUEST,
  REMOVE_ALL_LISTINGS_FROM_CAMPAIGN_SUCCESS,
  REMOVE_ALL_LISTINGS_FROM_CAMPAIGN_FAILURE,
  REMOVE_LISTINGS_FROM_CAMPAIGN_REQUEST,
  REMOVE_LISTINGS_FROM_CAMPAIGN_SUCCESS,
  REMOVE_LISTINGS_FROM_CAMPAIGN_FAILURE,
  SAVE_MORTGAGE_CALCULATOR_SUCCESS,
  SAVE_MORTGAGE_CALCULATOR_FAILURE,
  FETCH_CAMPAIGN_BY_SID_SUCCESS,
  FETCH_CAMPAIGN_BY_SID_FAILURE,
  FETCH_CAMPAIGN_BY_SID_REQUEST,
} from "../actions/actionTypes";

const initialState = {
  campaigns: [],
  campaign: null,
  loading: false,
  removingListings: false,
  error: null,
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTINGS_FROM_CAMPAIGN_REQUEST:
      return {
        ...state,
        removingListings: true,
        error: null,
      };
    case REMOVE_LISTINGS_FROM_CAMPAIGN_SUCCESS:
      // Update state after successfully removing listings
      // Example: Remove listings from campaign in state
      return {
        ...state,
        removingListings: false,
        error: null,
      };
    case REMOVE_LISTINGS_FROM_CAMPAIGN_FAILURE:
      // Handle error case
      return {
        ...state,
        removingListings: false,
        error: action.error,
      };
    case FETCH_CAMPAIGNS_REQUEST:
    case CREATE_CAMPAIGN_REQUEST:
    case UPDATE_CAMPAIGN_REQUEST:
    case DELETE_CAMPAIGN_REQUEST:
    case FETCH_CAMPAIGN_REQUEST:
    case FETCH_CAMPAIGN_BY_SID_REQUEST:
    case REMOVE_LISTING_FROM_CAMPAIGN_REQUEST:
    case REMOVE_ALL_LISTINGS_FROM_CAMPAIGN_REQUEST:
      return { ...state, loading: true };
    case FETCH_CAMPAIGNS_SUCCESS:
      return { ...state, loading: false, campaigns: action.payload };
    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: [...state.campaigns, action.payload],
      };
    case UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: state.campaigns.map((campaign) =>
          campaign._id === action.payload._id ? action.payload : campaign
        ),
        campaign: action.payload,
      };
    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.filter(
          (campaign) => campaign._id !== action.payload
        ),
      };
    case DELETE_CAMPAIGN_FAILURE:
      return { ...state, error: action.payload };

    case FETCH_CAMPAIGN_SUCCESS:
      return { ...state, loading: false, campaign: action.payload };

    case FETCH_CAMPAIGN_BY_SID_SUCCESS:
      return { ...state, loading: false, campaign: action.payload };

    case REMOVE_LISTING_FROM_CAMPAIGN_SUCCESS:
      if (!state.campaign) {
        return state;
      }
      return {
        ...state,
        loading: false,
        campaign: {
          ...state.campaign,
          listings: state.campaign.listings.filter(
            (listing) => listing._id !== action.payload
          ),
        },
      };
    case REMOVE_ALL_LISTINGS_FROM_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        campaign: {
          ...state.campaign,
          listings: [],
        },
      };
    case FETCH_CAMPAIGNS_FAILURE:
    case FETCH_CAMPAIGN_BY_SID_FAILURE:
    case CREATE_CAMPAIGN_FAILURE:
    case UPDATE_CAMPAIGN_FAILURE:
    case FETCH_CAMPAIGN_FAILURE:
    case REMOVE_LISTING_FROM_CAMPAIGN_FAILURE:
    case REMOVE_ALL_LISTINGS_FROM_CAMPAIGN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case SAVE_MORTGAGE_CALCULATOR_SUCCESS:
      return {
        ...state,
        mortgageCalculatorData: action.payload,
      };
    case SAVE_MORTGAGE_CALCULATOR_FAILURE:
      return {
        ...state,
        // Handle the failure state if needed
      };

    default:
      return state;
  }
};

export default campaignReducer;
