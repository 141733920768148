import React, { useState, useEffect } from "react";
import { Grid, Container, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchCampaignBySID } from "../actions/campaignActions"; // Assuming you have this action creator
import { getCampaignBySID } from "../selectors"; // Import getCampaignBySID from your selectors file
import { Helmet } from "react-helmet"; // Import Helmet

import CentralContent from "./CentralContent";
import HomeValuation from "../components/HomeValuation";
import FindMyDreamHome from "../components/FindMyDreamHome";
import MortgageCalculator from "../components/MortgageCalculator";
import AskMeAnything from "../components/AskMeAnything";
import Footer from "../components/Footer";


const LandingPage = () => {
  const { shortId } = useParams();
  const SID = shortId.toLowerCase();
  const dispatch = useDispatch();

  // Add the external script to the DOM
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cdn.jsdelivr.net/gh/realty-ai-org/RealtyAIChat@main/dist/web.js";
    script.type = "module";
    script.onload = () => {
      if (window.Chatbot) {
        window.Chatbot.init({ userID: "33e8b6e8-d7e3-4db0-8dfc-b0928c0b270d" });
      }
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchCampaignBySID(SID));
  }, [dispatch, SID]);

  const campaign = useSelector((state) => getCampaignBySID(state, SID));

  const [yearPurchased, setYearPurchased] = useState("");
  const [yearSold] = useState(new Date().getFullYear()); // Example value
  const [submitted, setSubmitted] = useState(false);

  // Display a loading spinner while campaign data is being fetched
  if (!campaign) {
    return (
      <Container sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Container>
    );
  }
  const campaignId = campaign.campaign._id;
  console.log("campaign", campaignId);
  return (
    <>
      <Helmet>
        <title>
          Home History Quiz - Powered by The Dan Morris Real Estate Team
        </title>
        <link rel="icon" type="image/png" href="/img/favicon.ico" />
      </Helmet>
      <Container
        sx={{
          mt: 0,
          mb: 4,
          borderRadius: 2,
          bgcolor: "rgba(0, 0, 0, 0.5)", // Darker background
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <CentralContent
              yearSold={yearSold}
              submitted={submitted}
              setYearPurchased={setYearPurchased}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <HomeValuation SID={SID} campaignId={campaignId} />
              </Grid>
              <Grid item xs={12}>
                <FindMyDreamHome SID={SID} campaignId={campaignId} />
              </Grid>
              <Grid item xs={12}>
                <MortgageCalculator SID={SID} campaignId={campaignId} />
              </Grid>
              <Grid item xs={12}>
                <AskMeAnything SID={SID} campaignId={campaignId} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Footer />
        <br />
      </Container>
    </>
  );
};

export default LandingPage;
