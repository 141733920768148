import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Button,
  Modal,
  Tooltip,
  styled,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete"; // Import delete icon
import { fetchLatestListing } from "../utils/services";
import {
  uploadListingImage,
  
} from "../actions/listingActions"; // Import removeListingFromCampaign action
import { removeListingFromCampaign } from "../actions/campaignActions";
import PostcardViewer from "./PostcardPDF";
import { arrayBufferToBase64 } from "../utils/helpers";

const WhiteTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#ffffff",
    color: "#000",
    boxShadow: "0px 3px 6px rgba(0,0,0,0.1)",
    fontSize: 11,
  },
}));

const ListingItem = ({
  listing,
  campaignId,
  onImageUpload,
  index,
  onUpdateDifferentListingsCount,
  onRemoveListing, // Function to remove listing from parent component
}) => {
  const [showPostcard, setShowPostcard] = useState(false);
  const [latestListing, setLatestListing] = useState(null);
  const [error, setError] = useState(null);
  const [fetchingLatest, setFetchingLatest] = useState(false); // State to control fetching

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch latest listing only if not already fetching
    if (!fetchingLatest) {
      fetchLatestData();
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const fetchLatestData = async () => {
    setFetchingLatest(true); // Start fetching
    try {
      const latest = await fetchLatestListing(listing.Address);
      setLatestListing(latest);

      // Check if the latest listing is different
      if (latest && latest._id !== listing._id) {
        onUpdateDifferentListingsCount(); // Notify CampaignPage of new different listing
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        setError(error.message);
        console.error(
          "Error fetching the latest listing:",
          listing.Address,
          error
        );
      }
    } finally {
      setFetchingLatest(false); // Finished fetching
    }
  };

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("image", file);
        dispatch(uploadListingImage(campaignId, listing._id, formData)).then(
          (response) => {
            if (
              response.data &&
              response.data.listing &&
              response.data.listing.image
            ) {
              onImageUpload(listing._id, response.data.listing.image);
            } else {
              console.error("Image upload response data is invalid");
            }
          }
        );
      }
    },
    [dispatch, campaignId, listing._id, onImageUpload]
  );

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const togglePostcard = useCallback(() => {
    setShowPostcard((prev) => !prev);
  }, []);

  const closeModal = () => {
    setShowPostcard(false);
  };

  const handleRemoveListing = () => {
    dispatch(removeListingFromCampaign(campaignId, listing._id))
      .then(() => {
        // Optionally handle success
      })
      .catch((error) => {
        console.error("Error removing listing:", error);
        // Optionally handle error
      });
  };

  const image = listing.image;
  const imageUrl = image?.data
    ? `data:${image.contentType};base64,${arrayBufferToBase64(image.data.data)}`
    : null;

  const isSameListing = latestListing && latestListing._id === listing._id;
  const isDifferentListing = latestListing && latestListing._id !== listing._id;

  const renderTooltipContent = (listing) => (
    <Box>
      <Typography variant="body2">List Price: {listing.ListPrice}</Typography>
      <Typography variant="body2">
        Listing Realtor: {listing.Licensee}
      </Typography>
    </Box>
  );

  return (
    <TableRow
      className="dropzone"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      sx={{
        backgroundColor: index % 2 === 0 ? "#effef2" : "#ffffff",
      }}
    >
      <TableCell sx={{ width: "100px", p: 1 }}>
        <img
          src={imageUrl ? imageUrl : "/img/imageholder.jpeg"}
          alt="Listing"
          style={{ height: "100px", width: "auto" }}
        />
      </TableCell>
      <TableCell
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          pl: 2,
          width: "100%",
        }}
      >
        <Typography
          sx={{ fontSize: "14px", fontWeight: "900", padding: 0, margin: 0 }}
          variant="h6"
        >
          {listing.Address}
        </Typography>
        {error && (
          <Typography color="error">
            Error fetching latest listing: {error}
          </Typography>
        )}
        {isSameListing && (
          <WhiteTooltip title={renderTooltipContent(listing)} arrow>
            <CheckIcon color="success" />
          </WhiteTooltip>
        )}
        {isDifferentListing && (
          <Box>
            <Typography variant="body2" color="error">
              New Listing! Realtor: {latestListing.Licensee}
            </Typography>
            <CloseIcon color="error" />
          </Box>
        )}
        <IconButton
          size="small"
          onClick={togglePostcard}
          sx={{ marginLeft: "auto" }} // Push the button to the right of the address
        >
          <VisibilityIcon />
        </IconButton>
        <IconButton
          size="small"
          onClick={handleRemoveListing} // Call handleRemoveListing when clicked
        >
          <DeleteIcon />
        </IconButton>
        <Table
          size="small"
          sx={{
            "& .MuiTableCell-sizeSmall": {
              padding: "0px 5px",
              margin: "0px",
              fontSize: "11px",
            },
          }}
        >
          <TableBody>
            <TableRow>
              <TableCell>MLS</TableCell>
              <TableCell>{listing.MLS}</TableCell>
              <TableCell>Last Status</TableCell>
              <TableCell>{listing.Status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Realtor</TableCell>
              <TableCell>
                {listing.Status === "SLD" ? listing.Buyer : listing.licensee}
              </TableCell>
              <TableCell>
                Date {listing.Status === "SLD" ? "Sold" : "Listed"}
              </TableCell>
              <TableCell>
                {new Date(listing.DateListed).toLocaleDateString()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableCell>
      <Modal
        open={showPostcard}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: 0,
          }}
        >
          <Typography id="simple-modal-title" variant="h6" component="h2">
            View Postcard
          </Typography>
          <PostcardViewer
            listings={[listing]}
            campaignId={campaignId}
            imageUrl={imageUrl}
            onClose={closeModal}
          />
          <Button onClick={closeModal} sx={{ mt: 2 }}>
            Close PDF
          </Button>
        </Box>
      </Modal>
    </TableRow>
  );
};

export default ListingItem;
