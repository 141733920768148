// src/components/PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

const isAuthenticated = () => {
  // Implementation of authentication check, e.g., check for a token
  return !!localStorage.getItem("token");
};

const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
