import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const NavBar = () => {
  const { auth, isAdmin, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(); // Reference to the menu container

  const handleLogout = (event) => {
    event.preventDefault();
    logout();
  };

  // Function to handle outside clicks
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false); // Close the menu if clicked outside
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Empty dependency array ensures this effect only runs once after the initial render

  return (
    <nav>
      <div className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
        <i>{isOpen ? "✖" : "☰"}</i>
      </div>
      {isOpen && (
        <ul className="nav-links" ref={menuRef}>
          {" "}
          {/* Assign ref to the menu */}
          <li>
            <Link to="/" onClick={() => setIsOpen(false)}>
              Home
            </Link>
          </li>
          {auth && (
            <>
              <li>
                <Link to="/listings" onClick={() => setIsOpen(false)}>
                  Listings
                </Link>
              </li>
              <li>
                <Link to="/analysis" onClick={() => setIsOpen(false)}>
                  Analysis
                </Link>
              </li>
              <li>
                <Link to="/mls-import" onClick={() => setIsOpen(false)}>
                  MLS Import
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  target="_blank"
                  to="/lp/KQSE"
                  onClick={() => setIsOpen(false)}
                >
                  Sample Postcard
                </Link>
              </li>
              {isAdmin && (
                <li>
                  <Link to="/register" onClick={() => setIsOpen(false)}>
                    Register User
                  </Link>
                </li>
              )}
              <li>
                <button onClick={handleLogout}>Logout</button>
              </li>
            </>
          )}
        </ul>
      )}
    </nav>
  );
};

export default NavBar;
