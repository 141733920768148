import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { sendHomeSearchRequestToServer } from "../utils/services"; // Import your server request function

const FindMyDreamHome = ({SID, campaignId}) => {
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [preferredArea, setPreferredArea] = useState("");
  const [budget, setBudget] = useState("");
  const [idealHomeDescription, setIdealHomeDescription] = useState("");
  const [contactMethod, setContactMethod] = useState("email");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationOpen, setNotificationOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    // Prepare your form data
    const formData = {
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      preferredArea,
      budget,
      idealHomeDescription,
      contactMethod,
      campaignId,
    };

    // Send the search request to the server
    const success = await sendHomeSearchRequestToServer(formData);

    // Handle success or failure
    if (success) {
      setNotificationMessage(
        "Your dream home search request has been submitted successfully!"
      );
      setNotificationOpen(true);
      handleClose(); // Close modal after successful submission
    } else {
      setNotificationMessage(
        "Failed to submit your request. Please try again later."
      );
      setNotificationOpen(true);
    }
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
    setNotificationMessage("");
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        sx={{
          border: "2px solid hsl(210deg 76% 47%)",
          borderRadius: "8px",
          p: 2,
          mt: 1,
          bgcolor: "white",
          textAlign: "center",
          cursor: "pointer", // Add cursor style for mouse icon
        }}
      >
        <Typography variant="h5" gutterBottom>
          Find My Dream Home
        </Typography>
        <Typography variant="body1">
          Maybe it's time for something new? Search for your dream home.
        </Typography>
        <IconButton
          onClick={handleOpen}
          color="primary"
          aria-label="find dream home"
        >
          <SearchIcon fontSize="large" />
        </IconButton>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: 800,
            maxHeight: "90vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              width: "100%",
              bgcolor: "hsl(210deg 76% 47%)",
              pl: 2,
              pr: 2,
              pt: 1,
              pb: 1,
              mb: 1,
              display: "flex",
              justifyContent: "space-between", // Center align horizontally
              alignItems: "stretch", // Align items vertically if needed
            }}
          >
            <SearchIcon fontSize="large" sx={{ color: "white" }} />
            <Typography
              variant="h5"
              component="h2"
              fontWeight={"bold"}
              color={"white"}
              sx={{ ml: 1 }} // Adjust margin left for spacing
            >
              Find Your Dream Home
            </Typography>
            <IconButton
              onClick={() => handleClose()}
              sx={{ float: "right", color: "white" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography variant="body1">
            Fill out the form below and we'll help you find your dream home!
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Email Address"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Preferred Area of the City - Where would you like to live?"
                value={preferredArea}
                onChange={(e) => setPreferredArea(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Budget - How much would you like to spend?"
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                aria-label="ideal-home-description"
                placeholder="Tell us about your ideal home...if you could wave a magic wand, what would it look like? How would it feel? Where would it be?"
                value={idealHomeDescription}
                multiline
                onChange={(e) => setIdealHomeDescription(e.target.value)}
                rows={9}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Preferred Contact Method - How would you like for us to connect
                with you?
              </FormLabel>
              <RadioGroup
                sx={{ display: "flex", flexDirection: "row", pl: 5 }}
                value={contactMethod}
                onChange={(e) => setContactMethod(e.target.value)}
              >
                <FormControlLabel
                  value="txt"
                  control={<Radio />}
                  label="Text"
                />
                <FormControlLabel
                  value="phone"
                  control={<Radio />}
                  label="Phone"
                />
                <FormControlLabel
                  value="email"
                  control={<Radio />}
                  label="Email"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid container justify="space-between" mt={2}>
            <Grid item xs={5}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={5}>
              <Button
                onClick={handleClose}
                variant="contained"
                color="error"
                fullWidth
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* Notification Modal */}
      <Modal
        open={notificationOpen}
        onClose={handleNotificationClose}
        aria-labelledby="notification-modal"
        aria-describedby="notification-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="notification-modal-title"
            variant="h5"
            fontWeight="bold"
            component="h2"
          >
            Notification
          </Typography>
          <Typography id="notification-modal-description" sx={{ mt: 2 }}>
            {notificationMessage}
          </Typography>
          <Button
            onClick={handleNotificationClose}
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
            fullWidth
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default FindMyDreamHome;
