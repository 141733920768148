import axios from "axios";

const GOOGLE_API_KEY = "AIzaSyAUfFjltKHJnqWiYb0qEXOG1IiJ3AX1nnk"; // Replace with your actual Google API key

// Function to get city and province from postal code using Geonames API
async function getCityFromPostalCode(postalCode) {
  // console.log(`looking up postal code: ${postalCode} from Geonames API line 7`);
  const url = `${process.env.REACT_APP_API_URL}/geonames?postalCode=${postalCode}`;

  try {
    return {
      city: "Nanaimo",
      province: "BC",
    };
    const response = await axios.get(url);
    const data = response.data;

    if (data.status && data.status.message) {
      if (
        data.status.message.includes(
          "the hourly limit of 1000 credits for realtyos has been exceeded"
        )
      ) {
        throw new Error(
          "Request limit exceeded: Please throttle your requests or consider using the commercial service."
        );
      }
      throw new Error(`Error from Geonames API: ${data.status.message}`);
    }

    if (data && data.city) {
      return {
        city: data.city,
        province: data.province,
      };
    } else {
      throw new Error("No data found for the given postal code.");
    }
  } catch (error) {
    console.error("Error fetching data from Geonames API:", error.message);
    throw new Error(error.message);
  }
}
// Function to get postal code from address using Google Maps Geocoding API
async function getPostalCodeFromAddress(address) {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address
  )}&key=${GOOGLE_API_KEY}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch postal code");
    }

    const data = await response.json();

    if (data.status !== "OK") {
      throw new Error("Invalid response from API");
    }

    const postalCode = data.results[0].address_components.find((component) =>
      component.types.includes("postal_code")
    )?.long_name;

    return postalCode;
  } catch (error) {
    throw new Error("Error fetching postal code:", error);
  }
}

export { getCityFromPostalCode, getPostalCodeFromAddress };
