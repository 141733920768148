const initialState = {
  results: [],
  isLoading: false, // Flag to track loading state
};

const analysisReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ANALYSIS_RESULTS_START":
      return {
        ...state,
        isLoading: true, // Set loading to true when fetch starts
      };
    case "FETCH_ANALYSIS_RESULTS_SUCCESS":
      return {
        ...state,
        results: action.payload,
        isLoading: false, // Set loading to false upon success
      };
    case "FETCH_ANALYSIS_RESULTS_FAILURE":
      return {
        ...state,
        isLoading: false, // Set loading to false upon failure
      };
    default:
      return state;
  }
};

export default analysisReducer;
