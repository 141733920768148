import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { jwtDecode } from "jwt-decode"; // Corrected import statement
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [auth, setAuth] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const DEBUG_MODE = true;
  const logoutTimeoutRef = useRef(null);

  // Define logout and setAutoLogout before their first use
  const logout = useCallback(() => {
    if (logoutTimeoutRef.current) {
      clearTimeout(logoutTimeoutRef.current); // Clear the timeout
    }
    localStorage.removeItem("token");
    setAuth(false);
    setIsAdmin(false);
    setUser(null);
    navigate("/login");
  }, [navigate]);

  const setAutoLogout = useCallback(
    (expirationTime) => {
      const msUntilExpiry = expirationTime * 1000 - Date.now();
      logoutTimeoutRef.current = setTimeout(() => {
        logout();
      }, msUntilExpiry);
    },
    [logout]
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
        if (decodedToken.exp < currentTime) {
          throw new Error("Token expired");
        }
        setAuth(true);
        setIsAdmin(decodedToken.isAdmin);
        setUser(decodedToken);
        setAutoLogout(decodedToken.exp);
      } catch (error) {
        console.error("Authentication error:", error);
        logout(); // Cleanup and redirect if token is invalid or expired
      }
    }
  }, [logout, setAutoLogout]); // Removed dependencies that are not required

  const login = async (username, password) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/login/l2`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("token", data.token);
        const decodedToken = jwtDecode(data.token);
        setAuth(true);
        setIsAdmin(decodedToken.isAdmin);
        setUser(decodedToken);
        navigate("/");
        setAutoLogout(decodedToken.exp);
      } else {
        throw new Error(data.message || "Invalid username or password");
      }
    } catch (error) {
      if (DEBUG_MODE) {
        console.error("Login error:", error);
      }
      setError(error.message);
    }
  };

  return (
    <AuthContext.Provider value={{ user, auth, isAdmin, login, logout, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
