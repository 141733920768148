// actions/contestActions.js

export const enterContest = (listingId, contactData) => async (dispatch) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/listings/${listingId}/enter-contest`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contactData),
      }
    );
    const data = await response.json();

    if (response.ok) {
      dispatch({
        type: "CONTEST_ENTRY_SUCCESS",
        payload: data.listing,
      });
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    dispatch({
      type: "CONTEST_ENTRY_FAIL",
      payload: error.message,
    });
    throw error;
  }
};
