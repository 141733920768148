import { createSelector } from "reselect";

// Analysis selectors
const getAnalysisState = (state) => state.analysis;

export const getAnalysisResults = createSelector(
  [getAnalysisState],
  (analysis) => analysis.results || []
);

export const getAnalysisLoading = createSelector(
  [getAnalysisState],
  (analysis) => analysis.isLoading
);

// Campaigns selectors
const getCampaignsState = (state) => state.campaigns;

export const getCampaignsData = createSelector(
  [getCampaignsState],
  (campaigns) => campaigns.campaigns || []
);

export const getCampaignsLoading = createSelector(
  [getCampaignsState],
  (campaigns) => campaigns.loading
);

export const getCampaignsError = createSelector(
  [getCampaignsState],
  (campaigns) => campaigns.error
);

export const getCampaignBySID = (state, SID) => {
  const campaign = state.campaigns?.campaign;
  return campaign;
};

// Listings selectors
const getListingsState = (state) => state.listings;

export const getListingsData = createSelector(
  [getListingsState],
  (listings) => listings.listings || []
);

export const getListingUploading = createSelector(
  [getListingsState],
  (listings) => listings.uploading
);

const selectListings = (state) => state.listings || [];

export const selectLatestListingByAddress = createSelector(
  [selectListings, (state, address) => address],
  (listings, address) => {
     if (!listings.length) {
       return null;
     }
    return listings
      .filter((listing) => listing.Address === address)
      .sort((a, b) => new Date(b.DateListed) - new Date(a.DateListed))[0];
  }
);
const getListingState = (state) => state.listing;

export const getListing = createSelector(
  [getListingState],
  (listingState) => listingState.listing
);

export const getListingLoading = createSelector(
  [getListingState],
  (listingState) => listingState.loading
);

export const getListingError = createSelector(
  [getListingState],
  (listingState) => listingState.error
);
